import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "src/utils/axios";

const initialState = {
  isLoading: false,
  error: null,
  order: [],
};

const orderPlaceSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    placeOrderItemsSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
      state.error = null;
    },
    placeOrderItemsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default orderPlaceSlice.reducer;

export const { getOrderItemsSuccess, getOrderItemsFailure } =
  orderPlaceSlice.actions;

export function placeOrderFromCart(
  item,
  customerId,
  total,
  sessionId,
  orderType,
  customer,
  tableId,
  method
  // tip
) {
  return async (dispatch) => {
    dispatch(orderPlaceSlice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/cart/createOrder`,
        {
          customerId: customerId,
          levelId: item?.levelId,
          venueId: item?.venueId,
          total: total,
          type: "web",
          sessionId: sessionId,
          orderType,
          customer: customer ? customer : {},
          tableId,
          paymentType: method,
          // tip
        }
      );
      dispatch(orderPlaceSlice.actions.placeOrderItemsSuccess(response?.data));
      if(orderType == 'QrDineIn' || orderType == 'QrPickUp'){
        localStorage.removeItem("mode");
      }
      return { data: response?.data, status: response.status };
    } catch (error) {
      dispatch(
        orderPlaceSlice.actions.placeOrderItemsFailure(
          error.response?.data?.message
        )
      );
      throw error;
    }
  };
}

export function getOrderByCustomerId(customerId) {
  return async (dispatch) => {
    dispatch(orderPlaceSlice.actions.startLoading());
    try {
      const response = await api.get(
        `${process.env.REACT_APP_BACKEND_URL}/cart/getOrderByCustomerId?id=${customerId}`
      );
      dispatch(orderPlaceSlice.actions.placeOrderItemsSuccess(response?.data));
    } catch (error) {
      dispatch(
        orderPlaceSlice.actions.placeOrderItemsFailure(
          error.response?.data?.messags
        )
      );
    }
  };
}

export function getOrderBySessionId(sessionId) {
  return async (dispatch) => {
    dispatch(orderPlaceSlice.actions.startLoading());
    try {
      const response = await api.get(
        `${process.env.REACT_APP_BACKEND_URL}/cart/getOrderBySessionId?id=${sessionId}`
      );
      dispatch(orderPlaceSlice.actions.placeOrderItemsSuccess(response?.data));
    } catch (error) {
      dispatch(
        orderPlaceSlice.actions.placeOrderItemsFailure(
          error.response?.data?.messags
        )
      );
    }
  };
}
