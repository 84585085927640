import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import VenueHeader from "../VenueHeader";
import VenueCover from "./VenueCover";
import FooterLogin from "src/layouts/compact/FooterLogin";
import VenueProfileMenu from "./VenueProfileMenu";
import api from "src/utils/axios";
import { fetchImage } from "src/utils/fetchImage";
import { useSelector } from "react-redux";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";

const VenueProfiles = () => {
  const { venues } = useSelector((state) => state.venues);
  const { venueId } = useParams();
  const [menuData, setMenuData] = useState([]);
  const [modeOfVenue, setModeOfVenue] = useState({
    isPickUp: false,
    isDineIn: false,
  });
  const [venuesCoverData, setVenuesCoverData] = useState({
    detail: "",
    image: "",
    distance: "",
  });
  const [loading, setLoading] = useState(false);

  const fetchMenuData = useCallback(async () => {
    try {
      setLoading(true);
      const venue = venues.find((venue) => venue.id === venueId);
      const venueResponse = await api.get(
        `${process.env.REACT_APP_BACKEND_URL}/venues/${venueId}`
      );
      const res = await fetchImage(venueResponse?.data?.photoURL);
      setVenuesCoverData({
        detail: venueResponse?.data,
        image: res,
        distance: venue?.distance,
      });
      const menuResponse = await api.get(
        `${process.env.REACT_APP_BACKEND_URL}/menu/?levelId=${venueResponse?.data?.levels[0]?.id}&venueId=${venueId}`
      );
      setModeOfVenue({
        isPickUp: venueResponse?.data?.configurations?.isPickUp,
        isDineIn: venueResponse?.data?.configurations?.isDineIn,
      });
      setMenuData(menuResponse.data.results);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [venueId, venues]);

  useEffect(() => {
    fetchMenuData();
  }, [fetchMenuData]);

 
  return (
    <>
      <VenueHeader />
      {loading ? (
        <LoadingScreen width="100px" height="100px" marginT="0" h="100vh" />
      ) : (
        <>
          <VenueCover venuesCoverData={venuesCoverData} />
          <VenueProfileMenu
            item={menuData}
            modeOfVenue={modeOfVenue}
            venueId={venueId}
          />
        </>
      )}
      <FooterLogin />
    </>
  );
};

export default VenueProfiles;
