import React, { useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import SearchMenu from "src/pages/googlemap/SearchMenu";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getVenueListWithLocation } from "src/redux/slices/venueSlice";
import useGeolocation from "src/utils/geolocation";
import SvgColor from '../../components/svg-color';
import { useState } from 'react';
const libraries = ["places"];

export default function MapSearch({ open, onClose }) {
  const { triggerLocation, address, latitude, longitude } = useGeolocation();
  const [location, setLocation] = useState(null);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 100 * 1000,
    },
  });
  useEffect(() => {
    if (address) {
      setValue(address);
      clearSuggestions();
    }
  }, [address, setValue, clearSuggestions]);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const addressSearch = async () => {
    const adress = localStorage.getItem("address")
    const adress1 = localStorage.getItem("tempAddress")
    if (adress1 !== null) {
      localStorage.setItem("address", adress1);
    }

    if (adress == null || adress != adress1) {
      if(!latitude || !longitude){
        await dispatch(getVenueListWithLocation(location));
      }else{
        const data = `${latitude},${longitude}`;
        await dispatch(getVenueListWithLocation(data));
      }
    }
    
    if (open) {
      onClose();
    } else {
      navigate("/venue");
    }
  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDL9J82iDhcUWdQiuIvBYa0t5asrtz3Swk",
    //  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      const location = `${lat},${lng}`;
      localStorage.setItem("tempAddress", results[0]?.formatted_address);
      localStorage.setItem("mode", "dineIn");
      setLocation(location)
      // await dispatch(getVenueListWithLocation(location));
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const handleLocateMe = async () => {
    await triggerLocation();
    setValue(address);
    // let data = `${latitude},${longitude}`;
    // if(latitude && longitude){
    //   await dispatch(getVenueListWithLocation(data));
    // }

  };

  const handleClearSearch = () => {
    setValue("");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          padding: "30px 0px",

          "@media (max-width: 768px)": {
            flexDirection: "row",
            gap: "10px",
          },
          "@media (max-width: 1000px)": {
            flexDirection: "row",
            gap: "10px",
          },
        }}
      >
        <TextField
          disabled={!ready}
          name="value"
          value={value}
          onChange={(e) => handleInput(e)}
          placeholder="Enter your address"
          autoComplete="off"
          sx={{
            background: 'white',
            borderRadius: "30px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "30px",
              width: "760px",
              outline: "none",
              "@media (max-width: 1000px)": {
                width: "65vw",
              },
              "@media (max-width: 768px)": {
                width: "65vw",
              },
              "&:focus": {
              },
              "&:hover fieldset": {
              },
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {value ? (
                  <Button
                    onClick={handleClearSearch}
                    sx={{
                      color: "#FCA92E",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Iconify icon={"feather:x-circle"} />
                  </Button>
                ) : (
                  <Button
                    onClick={handleLocateMe}
                    sx={{
                      color: "#FCA92E",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <SvgColor

                      src="/assets/icons/locate-me.svg"
                      sx={{
                        width: 50,
                        height: 20,
                        zIndex: 9,
                        bottom: -5,
                        right: -5,
                        position: 'absolute',
                        color: 'white',
                        background: 'black'
                      }}
                    />

                  </Button>
                )}
              </InputAdornment>
            ),
          }}
        />

        <Button
          onClick={addressSearch}
          sx={{
            width: "80px",
            height: "54px",
            background: "#FCA92E",
            borderRadius: "13px",
            fontSize: "15px",
            "@media (max-width: 1000px)": {
              width: "20%",
            },

            "@media (max-width: 768px)": {
              width: "65px",
              height: "54px",
              fontSize: "18px",
            },
            "@media (max-width: 300px)": {
              width: "20%",
            },
          }}
          variant="contained"
        >
          <SvgColor
            src="/assets/icons/go-icon-black.svg"
            sx={{
              width: 50,
              height: 20,
              zIndex: 9,
              marginTop: '3px',
              marginLeft: '3px',
              position: 'absolute',
              color: 'white',
            }}
          />
        </Button>
      </Box>

      <SearchMenu status={status} onSelect={handleSelect} data={data} />
    </>
  );
}

