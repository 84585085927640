import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
// import mailReducer from './slices/mail';
// import chatReducer from './slices/chat';
import productReducer from "./slices/product";
// import calendarReducer from './slices/calendar';
// import kanbanReducer from './slices/kanban';
import login from "./slices/loginSlice";
import venues from "./slices/venueSlice";
import cardSlice from "./slices/cardSlice";
import choiceByGroup from "./slices/choiceByGroupSlice";
import orderPlaceSlice from "./slices/orderPlaceSlice";
import tableSlice from "./slices/tableSlice";

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const loginPersistConfig = {
  key: "login",
  storage,
  keyPrefix: "redux-",
  whitelist: ["isLoggedIn", "user"],
};
const cartPersistConfig = {
  key: "cardSlice",
  storage,
  keyPrefix: "redux-",
  whitelist: ["isPickUp", "isDineIn"],
};
const venuePersistConfig = {
  key: "venues",
  storage,
  keyPrefix: "redux-",
  whitelist: ["venues"],
};
const tablePersistConfig = {
  key: "table",
  storage,
  keyPrefix: "redux-",
  whitelist: ["tableData"],
};

const rootReducer = combineReducers({
  // mail: mailReducer,
  // chat: chatReducer,
  // calendar: calendarReducer,
  // kanban: kanbanReducer,
  login: persistReducer(loginPersistConfig, login),
  cardSlice: persistReducer(cartPersistConfig,cardSlice),
  choiceByGroup: choiceByGroup,
  order: orderPlaceSlice,
  venues: persistReducer(venuePersistConfig, venues),
  tableData: persistReducer(tablePersistConfig, tableSlice),
  product: persistReducer(productPersistConfig, productReducer),
});

export default rootReducer;
