import { useState } from "react";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  IconButton,
  InputAdornment,
  Alert,
  FormControlLabel,
  Checkbox,
  TextField,
  Autocomplete,
  Box,
  Typography,
  Divider
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../../components/iconify";
import FormProvider, { RHFTextField } from "../../../components/hook-form";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch } from "react-redux";
import { countries } from "src/assets/data";
import { useNavigate } from "react-router";
import { updateUser } from "src/redux/slices/loginSlice";
import { useSelector } from "react-redux";
import { useSnackbar } from "../../../components/snackbar";
import UpdatePassword from './updatePassword'
// import VerifiedIcon from '@mui/icons-material/Verified';

export default function UserProfile() {
  const { user } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required("First name required").matches(/^[a-zA-Z ]*$/, "This field contains only alphabets"),
    lastName: Yup.string().required("Last name required").matches(/^[a-zA-Z ]*$/, "This field contains only alphabets"),
    email: Yup.string().required("Email is required").matches(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/,"Invalid Email Format"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^\d*$/, "This field contains only numbers")
      .test('len', 'This field contains 10 digits', val => val && val.length === 10),
    dob: Yup.date()
    .nullable()
    .transform((value, originalValue) => {
      const parsedDate = Date.parse(originalValue);
      return !isNaN(parsedDate) ? new Date(parsedDate) : null;
    })
    .max(new Date(), "Date can't be in the future")
    .required("Date of birth is required"),
    countryCode: Yup.string().required("Code"),
  });

  const defaultValues = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phone: user?.phone,
    dob: user?.dob,
    countryCode: user?.countryCode,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const { password, rpassword, ...userData } = data;
      const userDataToSend = {
        ...userData,
        ...(password && { password }),
      };
      const response = await dispatch(updateUser(userDataToSend, user?.id));
      if (response.status === 200) {
        enqueueSnackbar("Profile updated successfully");
        // navigate("/auth/login");
      }
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar(`${error?.response?.data?.message}`, {
        variant: "error",
      });
      reset();
      setError("afterSubmit", {
        ...error,
        message: error?.response?.data?.message,
      });
    }
  };

  return (
    <>
    <Box>
      <Typography variant="h3" mb={2} mt={8}>
        Profile
      </Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}

          <RHFTextField name="firstName" label="Enter your First name" />
          <RHFTextField name="lastName" label="Enter your Last name" />

          <Controller
            name="dob"
            control={control}
            maxDate={new Date()}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                label="Date of Birth"
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          />
        </Stack>
        <Stack sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <LoadingButton
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            // loading={isSubmitting || isSubmitSuccessful}
            sx={{
              width: "15%",
              marginTop: "15px",
              bgcolor: "#FCA92E",
              color: (theme) =>
                theme.palette.mode === "light" ? "#000000" : "grey.800",
              "&:hover": {
                bgcolor: "text.primary",
                color: (theme) =>
                  theme.palette.mode === "light" ? "common.white" : "grey.800"
              }
            }}
          >
            Update
          </LoadingButton>
        </Stack>
      </FormProvider>
      <Divider sx={{marginTop:"30px"}}/>
      </Box>
      <Box>
      <Typography variant="h3" mb={2} mt={8}>
        Verification
      </Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Box display="flex" alignItems="center">
          <RHFTextField name="email" label="Enter your Email address" />
          {/* <VerifiedIcon color="primary" sx={{ ml: 1 }} /> */}
        </Box>

        <Stack sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
        <Controller
            name="countryCode"
            control={control}
            defaultValue={countries?.find(country => country.code === "PK")}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={countries}
                getOptionLabel={(option) => option.code + "+" + option.phone}
                filterOptions={(options, { inputValue }) =>
                  options.filter(option =>
                    option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
                    option.flag.toLowerCase().includes(inputValue.toLowerCase())
                  )
                }
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Code"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      style: { width: 'auto' }  

                    }}
                  />
                )}
                sx={{ width: '120px' }}
                onChange={(_, data) => field.onChange(data)}
              />
            )}
          />
            <RHFTextField
              sx={{ borderRadius: "transparent" }}
              name="phone"
              label="Phone number"
            />
          </Stack>
        </Stack>
        <Stack sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <LoadingButton
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            // loading={isSubmitting || isSubmitSuccessful}
            sx={{
              width: "15%",
              marginTop: "15px",
              bgcolor: "#FCA92E",
              color: (theme) =>
                theme.palette.mode === "light" ? "#000000" : "grey.800",
              "&:hover": {
                bgcolor: "text.primary",
                color: (theme) =>
                  theme.palette.mode === "light" ? "common.white" : "grey.800"
              }
            }}
          >
            Update
          </LoadingButton>
        </Stack>
      </FormProvider>
      <Divider sx={{marginTop:"30px"}}/>
      </Box>
    <UpdatePassword />
    </>
  );
}
