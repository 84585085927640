import React from "react";
import { StyledBox, StyledMenuItem } from "./style";
import { splitText } from "src/utils/splitText";

const SearchMenu = ({ onSelect, data }) => {
  return (
    <>
      {data.length > 0 && (
        <StyledBox>
          {data.map(({ description, index }) => (
            <StyledMenuItem key={index} onClick={() => onSelect(description)}>
              {splitText(description)}
            </StyledMenuItem>
          ))}
        </StyledBox>
      )}
    </>
  );
};

export default SearchMenu;
