import { Box, Grid, Stack, Typography, Container } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import { fetchImage } from "src/utils/fetchImage";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrderBySessionId,
  getOrderByCustomerId,
} from "src/redux/slices/orderPlaceSlice";
import useSession from "src/utils/useSession";
import { convertTimeFormat } from "src/utils/formatTime";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import EmptyContent from "src/components/empty-content/EmptyContent";
import Image from "src/components/image/Image";
import ProcessingModal from "./processModdal/ProcessingModal";
import { socket } from "src/App";

const ActiveOrder = () => {
  const sessionInfo = useSession();
  const { order, isLoading } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.login);
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const dispatch = useDispatch();
  const getOrder = useCallback(async () => {
    try {
      await dispatch(getOrderBySessionId(sessionInfo?.sessionId));
    } catch (error) {
      console.log("error", error);
    }
  }, [order]);
  const [detail, setDetail] = useState("");

  const [imageURLs, setImageURLs] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const ItemDetails = (elem) => {
    setDetail(elem);
    setOpenConfirm(true);
  };

  useEffect(() => {
    if (order && order.length > 0) {
      const filtered = order.filter((item) =>
        ["pending", "processing", "finished"].includes(item.state)
      );
      setFilteredOrders(filtered);
    }
  }, [order]);

  // const filteredOrders =
  //   order &&
  //   order.length > 0 &&
  //   order?.filter((item) =>
  //     ["pending", "processing", "finished"].includes(item.state)
  //   );
  const hasFilteredOrders = filteredOrders && filteredOrders.length > 0;

  useEffect(() => {
    const fetchImagesForOrders = async () => {
      if (filteredOrders && filteredOrders.length > 0) {
        const urls = await Promise.all(
          filteredOrders.map(async (elem) => {
            try {
              const res = await fetchImage(elem.venueId.photoURL);
              return res;
            } catch (error) {
              console.error("Error fetching data:", error);
              return null;
            }
          })
        );
        setImageURLs(urls);
      }
    };

    fetchImagesForOrders();
  }, [hasFilteredOrders]);

  // useEffect(() => {
  //   if (filteredOrders && filteredOrders.length > 0) {
  //     const hasStateChanged = filteredOrders.some((item) =>
  //       ["pending", "processing", "finished"].includes(item.state)
  //     );
  //     if (hasStateChanged) {
  //       getOrder();
  //     }
  //   }
  //   const intervalId = setInterval(() => {
  //     if (filteredOrders && filteredOrders.length > 0) {
  //       const hasStateChanged = filteredOrders.some((item) =>
  //         ["pending", "processing", "finished"].includes(item.state)
  //       );
  //       if (hasStateChanged) {
  //         getOrder();
  //       }
  //     }
  //   }, 60000);
  //   return () => clearInterval(intervalId);
  // }, [filteredOrders]);

  useEffect(() => {
    const hasStateChanged =
      filteredOrders &&
      filteredOrders.length > 0 &&
      filteredOrders.some((item) =>
        ["pending", "processing", "finished"].includes(item.state)
      );
    if (hasStateChanged) {
      getOrder();
    }
  }, [hasFilteredOrders]);

  useEffect(() => {
    socket.on('changeState', async (data) => {
      setFilteredOrders(prevFiltredOrders => {
        if(prevFiltredOrders){
          const objectFound = prevFiltredOrders.find(obj => obj._id === data.id);
          if (objectFound) {
            const filteredOrder = prevFiltredOrders.filter(order => order._id !== data.id);
            if (data.state !== "declined" && data.state !== "completed" && data.state !== "abort") {
              return [...filteredOrder, data];
            } else {
              setOpenConfirm(false);
              return [...filteredOrder]
            }
          }else{
            return prevFiltredOrders
          }
        }
        else{
          return prevFiltredOrders
        }
      })
    })
  }, [socket]);

  useEffect(() => {
    if (openConfirm && detail) {
      const updatedDetail = filteredOrders.find((item) => item._id === detail._id);
      if (updatedDetail) {
        setDetail(updatedDetail);
      }else{
        handleCloseConfirm()
      }
    }
  }, [filteredOrders, openConfirm, detail]);

  return (
    <>
      <Box
        sx={{
          marginLeft: "35px",
          marginRight: "35px",
          "@media (max-width: 1025px)": {
            marginLeft: "0px",
            marginRight: "0px",
          },
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              marginTop: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              overflow: "hidden",
              "@media (max-width: 899px)": {
                marginTop: "150px",
              },
            }}
          >
            <Stack>
              <Typography variant="h3">Active Order</Typography>
            </Stack>
          </Box>
          {isLoading ? (
            <LoadingScreen  width="100px" height="100px" marginT="0" h="70vh"/>
          ) : (
            <>
              {hasFilteredOrders ? (
                <Box>
                  {filteredOrders?.length > 0 ? (
                    <Grid container spacing={3} mt={1} mb={5}>
                      {filteredOrders &&
                        filteredOrders?.map((elem, index) => {
                          return (
                            <Grid item xs={12} md={6} key={index}>
                              <Box
                                onClick={() => ItemDetails(elem)}
                                sx={{
                                  flexDirection: "row",
                                  padding: "10px",
                                  border: "1px solid rgba(0, 0, 0, 0.15)",
                                  borderRadius: "5px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                  cursor: "pointer",
                                }}
                              >
                                <Stack
                                  sx={{
                                    flexDirection: "row",
                                  }}
                                >
                                  <Box>
                                    <Image
                                      src={imageURLs[index]?imageURLs[index]:'/assets/placeholder.png'}
                                      alt={"image"}
                                      style={{
                                        height: "120px",
                                        width: "185px",
                                        borderRadius: "5px",
                                      }}
                                    />
                                    {/* <Typography
                                      variant="span"
                                      sx={{ fontWeight: 600, fontSize: "12px" }}
                                    >
                                      Ordered On
                                    </Typography> */}
                                    {/* <Typography
                                      variant="span"
                                      ml={1}
                                      sx={{
                                        color: "#F08203",
                                        marginTop: "4px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {convertTimeFormat(elem?.createdAt)}
                                    </Typography> */}
                                  </Box>
                                  <Box
                                    sx={{
                                      marginTop: "8px",
                                      marginLeft: "15px",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    <Typography sx={{ fontWeight: 600 }}>
                                      {elem?.venueId?.name}
                                    </Typography>
                                    <Typography sx={{ fontWeight: 600 }}>
                                      Order #
                                      {elem?._id?.replace(/^.{18}/, "OD")}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#F08203",
                                        marginTop: "4px",
                                        fontSize: "16px",
                                        "@media (max-width: 1440px)": {
                                          fontSize: "14px",
                                        },
                                      }}
                                    >
                                      Rs. {elem?.total}
                                    </Typography>
                                    <Typography
                                      variant="span"
                                      sx={{
                                        // color: "#F08203",
                                        marginTop: "4px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {convertTimeFormat(elem?.createdAt)}
                                    </Typography>
                                    {/* {elem?.items?.map((item, index) => {
                                      return (
                                        <Stack
                                          key={index}
                                          sx={{ flexDirection: "column" }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              "@media (max-width: 1440px)": {
                                                fontSize: "12px",
                                              },
                                            }}
                                          >
                                            {item?.qty} X {item?.name}
                                          </Typography>
                                        </Stack>
                                      );
                                    })} */}
                                  </Box>
                                </Stack>
                              </Box>
                            </Grid>
                          );
                        })}
                    </Grid>
                  ) : (
                    <EmptyContent
                      title="No Order"
                      sx={{
                        "& span.MuiBox-root": { height: 160 },
                      }}
                    />
                  )}
                </Box>
              ) : (
                <EmptyContent
                  title="No Active Order"
                  sx={{
                    "& span.MuiBox-root": { height: 160 },
                  }}
                />
              )}
            </>
          )}
        </Container>
      </Box>

      {openConfirm && (
        <ProcessingModal
          width="sm"
          open={openConfirm}
          onClose={handleCloseConfirm}
          detail={detail}
        />
      )}
    </>
  );
};
export default ActiveOrder;
