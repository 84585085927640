import React, { createContext, useContext, useState } from "react";

const CartContext = createContext();

export function CartProvider({ children }) {
  const [cart, setCart] = useState([]);
  const [isCartOpen, setCartOpen] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [selectedTip, setSelectedTip] = useState(null);

  const openCart = () => {
    setCartOpen(true);
  };

  const closeCart = () => {
    setCartOpen(false);
  };

  const toggleCart = () => {
    setCartOpen((prevOpen) => !prevOpen);
  };

  const selectVenue = (venue) => {
    setSelectedVenue(venue);
  };

  const addToCart = (item, quantity, selectedSauces, selectedAddOns) => {
    const existingCartItem = cart.find(
      (cartItem) => cartItem.title === item.title
    );

    if (existingCartItem) {
      const updatedCart = cart.map((cartItem) =>
        cartItem.title === item.title
          ? {
            ...cartItem,
            quantity: cartItem.quantity + quantity,
            selectedSauces: selectedSauces || cartItem.selectedSauces,
            selectedAddOns: selectedAddOns || cartItem.selectedAddOns,
          }
          : cartItem
      );

      setCart(updatedCart);
    } else {
      setCart([
        ...cart,
        {
          ...item,
          quantity,
          selectedSauces: selectedSauces || [],
          selectedAddOns: selectedAddOns || [],
        },
      ]);
    }
  };

  const removeFromCart = (itemId) => {
    setCart((prevCart) => prevCart.filter((item) => item.title !== itemId));
  };

  const handleTipChange = (tipValue) => {
    setSelectedTip(tipValue);
  };
  const resetCart = () => {
    setSelectedTip(null);
  };
  const formatPriceWithOut = (value) => {
    return Math.round(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const calculateMenuItemTotal = (cartItem) => {
    // const basePrice = parseFloat(cartItem?.price?.replace("Rs. ", ""));
    const itemQuantity = cartItem.qty;
    let itemTotal = cartItem?.price * itemQuantity;
    if (cartItem.selectedAddOns && cartItem.selectedAddOns.length > 0) {
      cartItem.selectedAddOns.forEach((addon) => {
        itemTotal += parseFloat(addon.price.replace("Rs. ", ""));
      });
    }
    itemTotal = itemTotal.toFixed(0);
    return `Rs. ${itemTotal}`;
    // return `Rs. ${formatPriceWithOut(parseFloat(itemTotal))}`
  };
  const formatPrice = (value) => {
    return value
      .toFixed(2) 
      .replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
  };
  const calculateSubTotal = (cart) => {
    const grandTotal = cart.reduce((total, cartItem) => {
      const itemQuantity = cartItem.qty;
      let itemTotal = parseFloat(
        cartItem.priceWithChoiceGroup ? cartItem.priceWithChoiceGroup : cartItem.price
      ) * itemQuantity
      if (cartItem.selectedAddOns && cartItem.selectedAddOns.length > 0) {
        cartItem.selectedAddOns.forEach((addon) => {
          itemTotal += parseFloat(addon.price.replace("Rs. ", ""));
        });
      }
      return total + itemTotal;
    }, 0);
    return `Rs. ${grandTotal.toFixed(0)}`;
    // return `Rs. ${formatPrice(grandTotal)}`;
  };


  const calculateFinalTotal = (cart) => {
    const serviceCharge = 10;
    const itemsTotal = cart.reduce((total, cartItem) => {
      const itemQuantity = cartItem.qty;
      let itemTotal = parseFloat(
        cartItem.priceWithChoiceGroup ? cartItem.priceWithChoiceGroup : cartItem.price
      ) * itemQuantity

      if (cartItem.selectedAddOns && cartItem.selectedAddOns.length > 0) {
        cartItem.selectedAddOns.forEach((addon) => {
          itemTotal += parseFloat(addon.price.replace("Rs. ", ""));
        });
      }

      return total + itemTotal;
    }, 0);

    const grandTotal =
      itemsTotal + parseFloat(selectedTip || 0) + serviceCharge;
      return `Rs. ${grandTotal.toFixed(0)}`;
      // return `Rs. ${formatPrice(grandTotal)}`;
    };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        isCartOpen,
        openCart,
        closeCart,
        toggleCart,
        selectVenue,
        selectedVenue,
        selectedTip,
        handleTipChange,
        calculateMenuItemTotal,
        calculateFinalTotal,
        calculateSubTotal,
        resetCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}
