import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, TextField, Typography, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "../../../components/snackbar";
import { useSelector } from "react-redux";
import api from "src/utils/axios";
import { useNavigate } from "react-router-dom";

export default function CarInformation() {
  
  const navigate = useNavigate();

  let orderType = localStorage.getItem("mode");
  const { cardItems } = useSelector((state) => state?.cardSlice);
  const { isLoggedIn } = useSelector((state) => state.login);

  const { enqueueSnackbar } = useSnackbar();
  
  const schema = Yup.object().shape({
    carNumber: Yup.string()
      .required("Car number is required")
      .matches(/^[A-Z0-9-]*$/, "Invalid car number format"),
    notes: Yup.string()
      .required("Notes are required")
  });

  const defaultValues = {
    carNumber: "",
    notes: ""
  };

  const { handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  const onSubmit = async (data) => {
    try {
      const response = await api.patch(
        `${process.env.REACT_APP_BACKEND_URL}/cart/addCustomer/${cardItems.data.id}`,
        { customer: data, orderType: 'QrPickUp' }
      );
      if (isLoggedIn) {
        navigate(`/checkout`);
      } else {
        navigate(`/auth/login?type=${orderType}`);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      enqueueSnackbar("Error submitting form", { variant: "error" });
    }
  };

  return (
    <>
      <Typography variant="h4" mb={2} mt={8}>
        Car Information
      </Typography>
      <Divider sx={{ marginBottom: "20px" }} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Controller
            name="carNumber"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Car Number"
                variant="outlined"
                fullWidth
                error={!!errors.carNumber}
                helperText={errors.carNumber?.message}
              />
            )}
          />
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Notes"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                error={!!errors.notes}
                helperText={errors.notes?.message}
              />
            )}
          />
        </Stack>
        <Stack sx={{ display: "flex", flexDirection: "row-reverse", marginTop: "20px" }}>
          <LoadingButton
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            sx={{
              width: "20%",
              bgcolor: "#FCA92E",
              color: (theme) =>
                theme.palette.mode === "light" ? "#000000" : "grey.800",
              "&:hover": {
                bgcolor: "text.primary",
                color: (theme) =>
                  theme.palette.mode === "light" ? "common.white" : "grey.800"
              }
            }}
          >
            Submit
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
}

