import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useSession from "src/utils/useSession";
import {
  deleteCartBySessionId,
  getCartByCustomerId,
} from "src/redux/slices/cardSlice";

const QrVenueScan = () => {
  const { venueId } = useParams();
  const navigate = useNavigate();
  const sessionInfo = useSession();

  const dispatch = useDispatch();
  const { cardItems } = useSelector((state) => state?.cardSlice);

  const RemoveItemFromCart = async () => {
    try {
      if (cardItems?.data?.items?.length > 0) {
        let res = await dispatch(deleteCartBySessionId(sessionInfo?.sessionId));
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getCartByCustomerId(sessionInfo?.sessionId));
        if (cardItems?.data?.items?.length > 0) {
            let res = await RemoveItemFromCart();
            if (res?.status === 201) {
              localStorage.setItem("mode", "QrPickUp");
              navigate(`/venue/${venueId}`, { replace: true });
            }
          } else {
            localStorage.setItem("mode", "QrPickUp");
            navigate(`/venue/${venueId}`, { replace: true });
          }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
   <>
   </>
  );
};

export default QrVenueScan;
