import { Box, Typography, Divider, IconButton, Grid } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import Image from "src/components/image/Image";
import { DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useState, useCallback } from "react";
import { fetchImage } from "src/utils/fetchImage";
import { OrderProcessingStepper } from "src/pages/components/mui/MUIStepperPage";

const Ordermodal = ({ detail, width, open, onClose }) => {
  const [imageURLs, setImageURLs] = useState([]);
  const [imageURL, setImageURL] = useState("");

  const getImage = useCallback(
    async (item) => {
      try {
        let res = await fetchImage(item);
        setImageURLs((prevURLs) => [...prevURLs, res]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [detail]
  );

  const getImage1 = useCallback(
    async (item) => {
      try {
        let res = await fetchImage(item);
        setImageURL(res);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [detail]
  );

  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Image
                src={imageURL || getImage1(detail?.venueId?.photoURL)}
                alt={"image"}
                style={{
                  height: "100px",
                  width: "140px",
                  borderRadius: "5px",
                }}
              />
              <Box variant="h2">
                {detail?.venueId?.name}
                <Typography sx={{ fontWeight: 600 }}>
                  Order # {detail?._id?.replace(/^.{18}/, "OD")}
                </Typography>
              </Box>
            </Box>
            <IconButton aria-label="close" onClick={onClose}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="#fca92e"
              />
            </IconButton>
          </Box>

          <Box mt={3}>
            <Divider />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ typography: "body2" }}>
          <Box>
            {detail?.items?.map((item, index) => {
              return (
                <>
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                    mb={3}
                    mt={3}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Image
                        src={imageURLs[index] || getImage(item?.photoURL)}
                        alt={"image"}
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "5px",
                        }}
                      />
                      <Box>
                        <Box sx={{ fontSize: "14px" }}>
                          {" "}
                          {item?.qty} X {item?.name}{" "}
                        </Box>
                        {item?.groups?.items &&
                          item?.groups?.items?.length > 0 && (
                            <>
                              {item?.groups?.items?.map((sauce, index) => (
                                <Box
                                  ml={2}
                                  key={index}
                                  sx={{
                                    color: "#666",
                                    display: "flex",
                                    gap: "2px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {sauce?.name} :
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "#666",
                                      display: "flex",
                                      marginLeft: "5px",
                                      gap: "2px",
                                    }}
                                  >
                                    {sauce?.items?.map((sauce, sauceIndex) => (
                                      <span key={sauceIndex}>
                                        {sauce?.item}
                                        {sauceIndex !== sauce?.length - 1 &&
                                          ", "}
                                      </span>
                                    ))}
                                  </Typography>
                                </Box>
                              ))}
                            </>
                          )}
                        <Box ml={2} sx={{ fontSize: "14px", color: "red" }}>
                          {item?.notes}{" "}
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ color: "#F08203" }}>
                      {" "}
                      Rs. {item?.price * item?.qty}
                    </Box>
                  </Box>
                  <Divider />
                </>
              );
            })}
          </Box>
          <Divider />
        </DialogContent>

        <DialogActions sx={{ display: "block" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                Total
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px" }}
                align="right"
              >
                Rs: {detail?.total}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                Service Fee
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px" }}
                align="right"
              >
                Rs: 0
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                Total
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                sx={{ fontWeight: "500", fontSize: "14px", color: "#F08203" }}
                align="right"
              >
                Rs: {detail?.total}
              </Typography>
            </Grid>
          </Grid>
          {/* <OrderProcessingStepper/> */}
        </DialogActions>
      </ConfirmDialog>
    </>
  );
};
export default Ordermodal;
