// import React, { useEffect, useRef } from "react";

// const BAFLForm = () => {
//   // const cardNumberRef = useRef(null);
//   // const expiryMonthRef = useRef(null);
//   // const expiryYearRef = useRef(null);
//   // const securityCodeRef = useRef(null);
//   // const cardholderNameRef = useRef(null);
//   // const PaymentSession = {
//   //   configure: () => {},
//   //   updateSessionFromForm: () => {},
//   // };
//   // useEffect(() => {
//   //   const script = document.createElement("script");
//   //   script.src =
//   //     "https://test-bankalfalah.gateway.mastercard.com/form/version/74/merchant/TESTEGORA/session.js";
//   //   script.async = true;
//   //   document.body.appendChild(script);

//   //   const antiClickjackStyle = document.createElement("style");
//   //   antiClickjackStyle.id = "antiClickjack";
//   //   antiClickjackStyle.innerHTML = "body { display: none !important; }";
//   //   document.head.appendChild(antiClickjackStyle);

//   //   if (window.self === window.top) {
//   //     const antiClickjack = document.getElementById("antiClickjack");
//   //     antiClickjack.parentNode.removeChild(antiClickjack);
//   //   } else {
//   //     window.top.location = window.self.location;
//   //   }

//   //   PaymentSession.configure({
//   //     session: "SESSION0002508281662G7111166K49",
//   //     fields: {
//   //       card: {
//   //         number: "#card-number",
//   //         securityCode: "#security-code",
//   //         expiryMonth: "#expiry-month",
//   //         expiryYear: "#expiry-year",
//   //         nameOnCard: "#cardholder-name",
//   //       },
//   //     },
//   //     frameEmbeddingMitigation: ["javascript"],
//   //     callbacks: {
//   //       initialized: (response) => {},
//   //       formSessionUpdate: (response) => {
//   //         if (response.status) {
//   //           if ("ok" === response.status) {
//   //             console.log("Session updated with data: " + response.session.id);
//   //             if (response.sourceOfFunds.provided.card.securityCode) {
//   //               console.log("Security code was provided.");
//   //             }
//   //             if (
//   //               response.sourceOfFunds.provided.card.scheme === "MASTERCARD"
//   //             ) {
//   //               console.log("The user entered a Mastercard credit card.");
//   //             }
//   //           } else if ("fields_in_error" === response.status) {
//   //             console.log("Session update failed with field errors.");
//   //             if (response.errors.cardNumber) {
//   //               console.log("Card number invalid or missing.");
//   //             }
//   //             if (response.errors.expiryYear) {
//   //               console.log("Expiry year invalid or missing.");
//   //             }
//   //             if (response.errors.expiryMonth) {
//   //               console.log("Expiry month invalid or missing.");
//   //             }
//   //             if (response.errors.securityCode) {
//   //               console.log("Security code invalid.");
//   //             }
//   //           } else if ("request_timeout" === response.status) {
//   //             console.log(
//   //               "Session update failed with request timeout: " +
//   //                 response.errors.message
//   //             );
//   //           } else if ("system_error" === response.status) {
//   //             console.log(
//   //               "Session update failed with system error: " +
//   //                 response.errors.message
//   //             );
//   //           }
//   //         } else {
//   //           console.log("Session update failed: " + response);
//   //         }
//   //       },
//   //     },
//   //     interaction: {
//   //       displayControl: {
//   //         formatCard: "EMBOSSED",
//   //         invalidFieldCharacters: "REJECT",
//   //       },
//   //     },
//   //   });
//   // });

//   // const pay = () => {
//   //   const cardNumber = cardNumberRef.current.value;
//   //   const expiryMonth = expiryMonthRef.current.value;
//   //   const expiryYear = expiryYearRef.current.value;
//   //   const securityCode = securityCodeRef.current.value;
//   //   const cardholderName = cardholderNameRef.current.value;

//   //   // Validate and process form data (e.g., call PaymentSession.updateSessionFromForm)
//   //   console.log("Payment details:", {
//   //     cardNumber,
//   //     expiryMonth,
//   //     expiryYear,
//   //     securityCode,
//   //     cardholderName,
//   //   });

//   //   // Configure PaymentSession
//   //   PaymentSession.configure({
//   //     session: "SESSION0002508281662G7111166K49",
//   //     fields: {
//   //       card: {
//   //         number: "#card-number",
//   //         securityCode: "#security-code",
//   //         expiryMonth: "#expiry-month",
//   //         expiryYear: "#expiry-year",
//   //         nameOnCard: "#cardholder-name",
//   //       },
//   //     },
//   //     frameEmbeddingMitigation: ["javascript"],
//   //     callbacks: {
//   //       initialized: (response) => {},
//   //       formSessionUpdate: (response) => {
//   //         if (response.status) {
//   //           if ("ok" === response.status) {
//   //             console.log("Session updated with data: " + response.session.id);
//   //             if (response.sourceOfFunds.provided.card.securityCode) {
//   //               console.log("Security code was provided.");
//   //             }
//   //             if (response.sourceOfFunds.provided.card.scheme === "MASTERCARD") {
//   //               console.log("The user entered a Mastercard credit card.");
//   //             }
//   //           } else if ("fields_in_error" === response.status) {
//   //             console.log("Session update failed with field errors.");
//   //             if (response.errors.cardNumber) {
//   //               console.log("Card number invalid or missing.");
//   //             }
//   //             if (response.errors.expiryYear) {
//   //               console.log("Expiry year invalid or missing.");
//   //             }
//   //             if (response.errors.expiryMonth) {
//   //               console.log("Expiry month invalid or missing.");
//   //             }
//   //             if (response.errors.securityCode) {
//   //               console.log("Security code invalid.");
//   //             }
//   //           } else if ("request_timeout" === response.status) {
//   //             console.log(
//   //               "Session update failed with request timeout: " +
//   //                 response.errors.message
//   //             );
//   //           } else if ("system_error" === response.status) {
//   //             console.log(
//   //               "Session update failed with system error: " +
//   //                 response.errors.message
//   //             );
//   //           }
//   //         } else {
//   //           console.log("Session update failed: " + response);
//   //         }
//   //       },
//   //     },
//   //     interaction: {
//   //       displayControl: {
//   //         formatCard: "EMBOSSED",
//   //         invalidFieldCharacters: "REJECT",
//   //       },
//   //     },
//   //   });

//   //   // Update session from form
//   //   PaymentSession.updateSessionFromForm("card");
//   // };

//   // function loadError(onError) {
//   //   console.error(`Failed ${onError.target.src} didn't load correctly`);
//   // }

//   // React.useEffect(() => {
//   //   const LoadExternalScript = () => {
//   //     const externalScript = document.createElement("script");
//   //     externalScript.onerror = loadError;
//   //     externalScript.id = "external";
//   //     externalScript.async = true;
//   //     externalScript.type = "text/javascript";
//   //     externalScript.setAttribute("crossorigin", "anonymous");
//   //     document.body.appendChild(externalScript);
//   //     externalScript.src = `https://test-bankalfalah.gateway.mastercard.com/form/version/74/merchant/TESTEGORA/session.js`;
//   //   };
//   //   LoadExternalScript();
//   // }, []);

//   const devCode = `  <div>Please enter your payment details:</div>
// <h3>Credit Card</h3>
// <div>Card Number: <input type="text" id="card-number" class="input-field" title="card number" aria-label="enter your card number" value="" tabindex="1" readonly></div>
// <div>Expiry Month:<input type="text" id="expiry-month" class="input-field" title="expiry month" aria-label="two digit expiry month" value="" tabindex="2" readonly></div>
// <div>Expiry Year:<input type="text" id="expiry-year" class="input-field" title="expiry year" aria-label="two digit expiry year" value="" tabindex="3" readonly></div>
// <div>Security Code:<input type="text" id="security-code" class="input-field" title="security code" aria-label="three digit CCV security code" value="" tabindex="4" readonly></div>
// <div>Cardholder Name:<input type="text" id="cardholder-name" class="input-field" title="cardholder name" aria-label="enter name on card" value="" tabindex="5" readonly></div>
// <div><button id="payButton" onclick="pay();">Pay Now</button></div>

// <script src="https://test-bankalfalah.gateway.mastercard.com/form/version/74/merchant/TESTEGORA/session.js"></script>
// <style id="antiClickjack">body{display:none !important;}</style>
// <script type="text/javascript">
// if (self === top) {
//     var antiClickjack = document.getElementById("antiClickjack");
//     antiClickjack.parentNode.removeChild(antiClickjack);
// } else {
//     top.location = self.location;
// }

// PaymentSession.configure({
//     session: "SESSION0002981373740N2736093L06",
//     fields: {
//         card: {
//             number: "#card-number",
//             securityCode: "#security-code",
//             expiryMonth: "#expiry-month",
//             expiryYear: "#expiry-year",
//             nameOnCard: "#cardholder-name"
//         }
//     },
//     frameEmbeddingMitigation: ["javascript"],
//     callbacks: {
//         initialized: function(response) {},
//         formSessionUpdate: function(response) {
//             if (response.status) {
//                 if ("ok" == response.status) {
//                     console.log("Session updated with data: " + response.session.id);
//                     if (response.sourceOfFunds.provided.card.securityCode) {
//                         console.log("Security code was provided.");
//                     }
//                     if (response.sourceOfFunds.provided.card.scheme == 'MASTERCARD') {
//                         console.log("The user entered a Mastercard credit card.")
//                     }
//                 } else if ("fields_in_error" == response.status)  {
//                     console.log("Session update failed with field errors.");
//                     if (response.errors.cardNumber) {
//                         console.log("Card number invalid or missing.");
//                     }
//                     if (response.errors.expiryYear) {
//                         console.log("Expiry year invalid or missing.");
//                     }
//                     if (response.errors.expiryMonth) {
//                         console.log("Expiry month invalid or missing.");
//                     }
//                     if (response.errors.securityCode) {
//                         console.log("Security code invalid.");
//                     }
//                 } else if ("request_timeout" == response.status)  {
//                     console.log("Session update failed with request timeout: " + response.errors.message);
//                 } else if ("system_error" == response.status)  {
//                     console.log("Session update failed with system error: " + response.errors.message);
//                 }
//             } else {
//                 console.log("Session update failed: " + response);
//             }
//         }
//     },
//     interaction: {
//         displayControl: {
//             formatCard: "EMBOSSED",
//             invalidFieldCharacters: "REJECT"
//         }
//     }
//  });

// function pay() {
//     PaymentSession.updateSessionFromForm('card');
// }
// </script>
// `;

//   return (
//     <div
//     dangerouslySetInnerHTML={{ __html: devCode }}
//   ></div>
//   );
// };

// export default BAFLForm;

// // import React, { useEffect, useRef } from "react";

// // const BAFLForm = () => {
// //   const scriptRef = useRef(null);

// //   useEffect(() => {
// //     const script = document.createElement("script");
// //     script.src =
// //       "https://test-bankalfalah.gateway.mastercard.com/form/version/74/merchant/TESTEGORA/session.js";
// //     script.async = true;
// //     script.addEventListener("load", () => {
// //       // Script loaded successfully
// //     });
// //     document.body.appendChild(script);
// //   }, []);

// //   console.log("scriptRef", scriptRef);

// //   useEffect(() => {
// //     if (scriptRef.current) {
// //       document.body.appendChild(scriptRef.current);
// //     }
// //     return () => {
// //       if (scriptRef.current) {
// //         document.body.removeChild(scriptRef.current);
// //       }
// //     };
// //   }, [scriptRef]);

// //   const PaymentSession = {
// //     configure: () => {},
// //     updateSessionFromForm: () => {},
// //   };

// //   const pay = () => {
// //     PaymentSession.updateSessionFromForm("card");
// //   };

// //   return (
// //     <div>
// //       <h3>Credit Card</h3>
// //       <div>
// //         <div>Please enter your payment details:</div>
// //         <h3>Credit Card</h3>
// //         <div>
// //           Card Number:{" "}
// //           <input
// //             type="text"
// //             id="card-number"
// //             className="input-field"
// //             title="card number"
// //             aria-label="enter your card number"
// //             value=""
// //             tabIndex="1"
// //             readOnly
// //           />
// //         </div>
// //         <div>
// //           Expiry Month:
// //           <input
// //             type="text"
// //             id="expiry-month"
// //             className="input-field"
// //             title="expiry month"
// //             aria-label="two digit expiry month"
// //             value=""
// //             tabIndex="2"
// //             readOnly
// //           />
// //         </div>
// //         <div>
// //           Expiry Year:
// //           <input
// //             type="text"
// //             id="expiry-year"
// //             className="input-field"
// //             title="expiry year"
// //             aria-label="two digit expiry year"
// //             value=""
// //             tabIndex="3"
// //             readOnly
// //           />
// //         </div>
// //         <div>
// //           Security Code:
// //           <input
// //             type="text"
// //             id="security-code"
// //             className="input-field"
// //             title="security code"
// //             aria-label="three digit CCV security code"
// //             value=""
// //             tabIndex="4"
// //             readOnly
// //           />
// //         </div>
// //         <div>
// //           Cardholder Name:
// //           <input
// //             type="text"
// //             id="cardholder-name"
// //             className="input-field"
// //             title="cardholder name"
// //             aria-label="enter name on card"
// //             value=""
// //             tabIndex="5"
// //             readOnly
// //           />
// //         </div>
// //         <div>
// //           <button id="payButton" onClick={pay}>
// //             Pay Now
// //           </button>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default BAFLForm;

// // import React, { useState, useEffect } from "react";

// // const App = () => {
// //   const [page, setPage] = useState(null);

// //   useEffect(() => {
// //     fetch("BAFLForm/payment.html")
// //       .then(result => {
// //         return result.text();
// //       })
// //       .then(pageContent => {
// //         setPage({ __html: pageContent });

// //         const script = document.createElement('script');
// //         script.src = "BAFLForm/js/index.js";
// //         script.async = true;
// //         document.body.appendChild(script);
// //       });
// //   }, []);

// //   return (
// //     <>
// //       <h2>
// //         <span>Inserting project using React's </span>
// //         <code>dangerouslySetInnerHTML</code>:
// //       </h2>
// //       <div dangerouslySetInnerHTML={page} />
// //     </>
// //   );
// // };

// // export default App;

import React from "react";
import CryptoJS from "crypto-js";

const BAFLForm = ({ data }) => {
  const encryptedId = CryptoJS.AES.encrypt(
    data?.sessionId,
    'sufyan'
  ).toString();

  const iframeSrc = `BAFLForm/payment.html?id=${encodeURIComponent(encryptedId)}`;

  return (
    <div>
      <iframe
        src={iframeSrc}
        title="Payment Form"
        width="100%"
        height="200px"
        frameBorder="0"
      />
    </div>
  );
};

export default BAFLForm;
