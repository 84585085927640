import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Link,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Container,
} from "@mui/material";
import { _socials } from "../../_mock/arrays";
import Logo from "../../components/logo";
import Iconify from "../../components/iconify";
import googlePlayIcon from "../../assets/icons/google_play.svg";
import appleWIcon from "../../assets/icons/app_store.svg";
import Image from "src/components/image/Image";

const LINKS = [
  {
    headline: "Minimal",
    children: [
      { name: "Help Center", href: "" },
      { name: "About Us", href: "" },
      { name: "Careers", href: "" },
      { name: "Contact us", href: "" },
    ],
  },

];

const FooterLogin = () => {
  const mainFooter = (
    <Box component="footer">
      <Divider />
      <Box
        sx={{
          marginLeft: "35px",
          marginRight: "35px",
          "@media (max-width: 1025px)": {
            marginLeft: "0px",
            marginRight: "0px",
          },
        }}
      >
        <Container maxWidth="xl">
          <Stack
            spacing={1}
            justifyContent="space-between"
            direction={{ xs: "column", md: "row" }}
            alignItems={{ xs: "center", md: "flex-start" }}
            sx={{
              marginTop: 5,
              // marginLeft: 3,
              marginBottom: 5,
              "@media (max-width: 890px)": {
                marginLeft: 0,
              },
            }}
          >
            <Stack key="column-1" spacing={1} flexGrow={1}>
              <Logo sx={{ mx: { xs: "auto", md: "inherit" } }} />
              <Typography variant="body2">
                Hospitality meets innovation
              </Typography>
            </Stack>

            <Stack key="column-2" spacing={1} flexGrow={8}>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Typography variant="h5" sx={{ textAlign: "center", mt: -1 }}>
                  Download Our APP
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  variant="contained"
                  sx={{
                    background: "transparent",
                    height: "40px",
                    width: "130px",
                    padding: "1px",
                    color: (theme) =>
                      theme.palette.mode === "light" ? "#ffff" : "grey.800",
                    "&:hover": {
                      bgcolor: "text.primary",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                >
                  <Image src={googlePlayIcon} alt="google-play" />
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    background: "transparent",
                    height: "40px",
                    width: "130px",
                    padding: "1px",
                    color: (theme) =>
                      theme.palette.mode === "light" ? "#ffff" : "grey.800",
                    "&:hover": {
                      bgcolor: "text.primary",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                    },
                  }}
                >
                  <Image src={appleWIcon} alt="" />
                </Button>
              </Stack>
            </Stack>

            {LINKS.map((list) => (
              <Stack key={list.headline} spacing={1} flexGrow={0.4}>
                {list.children.map((link) => (
                  <Link
                    key={link.name}
                    component={RouterLink}
                    to={link.href}
                    color="inherit"
                    variant="body2"
                  >
                    {link.name}
                  </Link>
                ))}
              </Stack>
            ))}
          </Stack>
        </Container>
      </Box>

      <Divider />
      <Container maxWidth="xl">
        <Stack
          // spacing={1}
          justifyContent="space-between"
          direction={{ xs: "column", md: "row" }}
          alignItems={{ xs: "center", md: "flex-start" }}
          sx={{
            marginTop: 5,
            marginLeft: 2.5,
            marginBottom: 5,
            "@media (max-width: 890px)": {
              marginLeft: 0,
            },
          }}
        >
          <Stack key="column-3" spacing={1} flexGrow={8}>
            <div>
              {_socials.map((social) => (
                <IconButton key={social.name}>
                  <Iconify icon={social.icon} />
                </IconButton>
              ))}
            </div>
          </Stack>
          <Stack key="column-4" spacing={1} flexGrow={0.3}>
            <div>
              <Typography
                variant="caption"
                component="div"
                sx={{
                  pb: 5,
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                © Copyrights 2024 egora All rights reserved
              </Typography>
            </div>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );

  return mainFooter;
};

export default FooterLogin;
