import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "src/redux/slices/loginSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Alert, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FormProvider, { RHFTextField } from "../../../../components/hook-form";
import { useSnackbar } from "../../../../components/snackbar";

export default function GuestModalContent() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").matches(/^[a-zA-Z ]*$/, "This field contains only alphabets"),
    phone: Yup.string().required("Contact number is required").matches(/^\d*$/, "This field contains only numbers").test('len', 'This field contains 11 digits', val => val && val.length === 11),
    email: Yup.string().required("Email is required").matches(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/,"Invalid Email Format"),
  });

  const defaultValues = {
    name: "",
    phone: "",
    email: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const userData = {
        name: data.name,
        phone: data.phone,
        email: data.email,
      };
      localStorage.setItem("guestUser", JSON.stringify(userData));
      navigate("/checkout");
    } catch (error) {
      reset();
      setError("afterSubmit", {
        ...error,
        message: error?.response?.data?.message,
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ mt: 2 }} spacing={2}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <RHFTextField name="name" label="Name" />
        <RHFTextField name="phone" label="Contact Number" />
        <RHFTextField name="email" label="Email address" />
      </Stack>
      <Box mt={2}>
        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitSuccessful || isSubmitting}
          sx={{
            bgcolor: "#FCA92E",
            color: (theme) =>
              theme.palette.mode === "light" ? "#000000" : "grey.800",
            "&:hover": {
              bgcolor: "text.primary",
              color: (theme) =>
                theme.palette.mode === "light" ? "common.white" : "grey.800",
            },
          }}
        >
          Continue
        </LoadingButton>
      </Box>
    </FormProvider>
  );
}
