import React from "react";
import AuthLoginForm from "../../AuthLoginForm";
import { LoadingButton } from "@mui/lab";
import { Box, Divider } from "@mui/material";
import GuestModalContent from "./GuestModalContent";

const LoginModalContent = ({ toggle, setToggle }) => {
  let orderType = localStorage.getItem("mode");

  return (
    <>
      {!toggle ? (
        <>
          <AuthLoginForm />
          {orderType === "dineIn" && (
            <Box mt={2} mb={3}>
              <Box mt={2} mb={3}>
                <Divider />
              </Box>
              <LoadingButton
                onClick={() => setToggle(true)}
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "#FCA92E",
                  color: (theme) =>
                    theme.palette.mode === "light" ? "#000000" : "grey.800",
                  "&:hover": {
                    bgcolor: "text.primary",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "common.white"
                        : "grey.800",
                  },
                }}
              >
                Skip
              </LoadingButton>
            </Box>
          )}
        </>
      ) : (
        <GuestModalContent />
      )}
    </>
  );
};

export default LoginModalContent;
