export const countries = [
  { code: "AD", label: "Andorra", phone: "376", flag: "🇦🇩" },
  { code: "AE", label: "United Arab Emirates", phone: "971", flag: "🇦🇪" },
  { code: "AF", label: "Afghanistan", phone: "93", flag: "🇦🇫" },
  { code: "AG", label: "Antigua and Barbuda", phone: "1-268", flag: "🇦🇬" },
  { code: "AI", label: "Anguilla", phone: "1-264", flag: "🇦🇮" },
  { code: "AL", label: "Albania", phone: "355", flag: "🇦🇱" },
  { code: "AM", label: "Armenia", phone: "374", flag: "🇦🇲" },
  { code: "AO", label: "Angola", phone: "244", flag: "🇦🇴" },
  { code: "AQ", label: "Antarctica", phone: "672", flag: "🇦🇶" },
  { code: "AR", label: "Argentina", phone: "54", flag: "🇦🇷" },
  { code: "AS", label: "American Samoa", phone: "1-684", flag: "🇦🇸" },
  { code: "AT", label: "Austria", phone: "43", flag: "🇦🇹" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    flag: "🇦🇺",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297", flag: "🇦🇼" },
  { code: "AX", label: "Alland Islands", phone: "358", flag: "🇦🇽" },
  { code: "AZ", label: "Azerbaijan", phone: "994", flag: "🇦🇿" },
  { code: "BA", label: "Bosnia and Herzegovina", phone: "387", flag: "🇧🇦" },
  { code: "BB", label: "Barbados", phone: "1-246", flag: "🇧🇧" },
  { code: "BD", label: "Bangladesh", phone: "880", flag: "🇧🇩" },
  { code: "BE", label: "Belgium", phone: "32", flag: "🇧🇪" },
  { code: "BF", label: "Burkina Faso", phone: "226", flag: "🇧🇫" },
  { code: "BG", label: "Bulgaria", phone: "359", flag: "🇧🇬" },
  { code: "BH", label: "Bahrain", phone: "973", flag: "🇧🇭" },
  { code: "BI", label: "Burundi", phone: "257", flag: "🇧🇮" },
  { code: "BJ", label: "Benin", phone: "229", flag: "🇧🇯" },
  { code: "BL", label: "Saint Barthelemy", phone: "590", flag: "🇧🇱" },
  { code: "BM", label: "Bermuda", phone: "1-441", flag: "🇧🇲" },
  { code: "BN", label: "Brunei Darussalam", phone: "673", flag: "🇧🇳" },
  { code: "BO", label: "Bolivia", phone: "591", flag: "🇧🇴" },
  { code: "BR", label: "Brazil", phone: "55", flag: "🇧🇷" },
  { code: "BS", label: "Bahamas", phone: "1-242", flag: "🇧🇸" },
  { code: "BT", label: "Bhutan", phone: "975", flag: "🇧🇹" },
  { code: "BV", label: "Bouvet Island", phone: "47", flag: "🇧🇻" },
  { code: "BW", label: "Botswana", phone: "267", flag: "🇧🇼" },
  { code: "BY", label: "Belarus", phone: "375", flag: "🇧🇾" },
  { code: "BZ", label: "Belize", phone: "501", flag: "🇧🇿" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    flag: "🇨🇦",
    suggested: true,
  },
  { code: "CC", label: "Cocos (Keeling) Islands", phone: "61", flag: "🇨🇨" },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
    flag: "🇨🇩",
  },
  { code: "CF", label: "Central African Republic", phone: "236", flag: "🇨🇫" },
  { code: "CG", label: "Congo, Republic of the", phone: "242", flag: "🇨🇬" },
  { code: "CH", label: "Switzerland", phone: "41", flag: "🇨🇭" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225", flag: "🇨🇮" },
  { code: "CK", label: "Cook Islands", phone: "682", flag: "🇨🇰" },
  { code: "CL", label: "Chile", phone: "56", flag: "🇨🇱" },
  { code: "CM", label: "Cameroon", phone: "237", flag: "🇨🇲" },
  { code: "CN", label: "China", phone: "86", flag: "🇨🇳" },
  { code: "CO", label: "Colombia", phone: "57", flag: "🇨🇴" },
  { code: "CR", label: "Costa Rica", phone: "506", flag: "🇨🇷" },
  { code: "CU", label: "Cuba", phone: "53", flag: "🇨🇺" },
  { code: "CV", label: "Cape Verde", phone: "238", flag: "🇨🇻" },
  { code: "CW", label: "Curacao", phone: "599", flag: "🇨🇼" },
  { code: "CX", label: "Christmas Island", phone: "61", flag: "🇨🇽" },
  { code: "CY", label: "Cyprus", phone: "357", flag: "🇨🇾" },
  { code: "CZ", label: "Czech Republic", phone: "420", flag: "🇨🇿" },
  { code: "DE", label: "Germany", phone: "49", flag: "🇩🇪", suggested: true },
  { code: "DJ", label: "Djibouti", phone: "253", flag: "🇩🇯" },
  { code: "DK", label: "Denmark", phone: "45", flag: "🇩🇰" },
  { code: "DM", label: "Dominica", phone: "1-767", flag: "🇩🇲" },
  { code: "DO", label: "Dominican Republic", phone: "1-809", flag: "🇩🇴" },
  { code: "DZ", label: "Algeria", phone: "213", flag: "🇩🇿" },
  { code: "EC", label: "Ecuador", phone: "593", flag: "🇪🇨" },
  { code: "EE", label: "Estonia", phone: "372", flag: "🇪🇪" },
  { code: "EG", label: "Egypt", phone: "20", flag: "🇪🇬" },
  { code: "EH", label: "Western Sahara", phone: "212", flag: "🇪🇭" },
  { code: "ER", label: "Eritrea", phone: "291", flag: "🇪🇷" },
  { code: "ES", label: "Spain", phone: "34", flag: "🇪🇸" },
  { code: "ET", label: "Ethiopia", phone: "251", flag: "🇪🇹" },
  { code: "FI", label: "Finland", phone: "358", flag: "🇫🇮" },
  { code: "FJ", label: "Fiji", phone: "679", flag: "🇫🇯" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
    flag: "🇫🇰",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
    flag: "🇫🇲",
  },
  { code: "FO", label: "Faroe Islands", phone: "298", flag: "🇫🇴" },
  { code: "FR", label: "France", phone: "33", flag: "🇫🇷", suggested: true },
  { code: "GA", label: "Gabon", phone: "241", flag: "🇬🇦" },
  { code: "GB", label: "United Kingdom", phone: "44", flag: "🇬🇧" },
  { code: "GD", label: "Grenada", phone: "1-473", flag: "🇬🇩" },
  { code: "GE", label: "Georgia", phone: "995", flag: "🇬🇪" },
  { code: "GF", label: "French Guiana", phone: "594", flag: "🇬🇫" },
  { code: "GG", label: "Guernsey", phone: "44", flag: "🇬🇬" },
  { code: "GH", label: "Ghana", phone: "233", flag: "🇬🇭" },
  { code: "GI", label: "Gibraltar", phone: "350", flag: "🇬🇮" },
  { code: "GL", label: "Greenland", phone: "299", flag: "🇬🇱" },
  { code: "GM", label: "Gambia", phone: "220", flag: "🇬🇲" },
  { code: "GN", label: "Guinea", phone: "224", flag: "🇬🇳" },
  { code: "GP", label: "Guadeloupe", phone: "590", flag: "🇬🇵" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240", flag: "🇬🇶" },
  { code: "GR", label: "Greece", phone: "30", flag: "🇬🇷" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
    flag: "🇬🇸",
  },
  { code: "GT", label: "Guatemala", phone: "502", flag: "🇬🇹" },
  { code: "GU", label: "Guam", phone: "1-671", flag: "🇬🇺" },
  { code: "GW", label: "Guinea-Bissau", phone: "245", flag: "🇬🇼" },
  { code: "GY", label: "Guyana", phone: "592", flag: "🇬🇾" },
  { code: "HK", label: "Hong Kong", phone: "852", flag: "🇭🇰" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
    flag: "🇭🇲",
  },
  { code: "HN", label: "Honduras", phone: "504", flag: "🇭🇳" },
  { code: "HR", label: "Croatia", phone: "385", flag: "🇭🇷" },
  { code: "HT", label: "Haiti", phone: "509", flag: "🇭🇹" },
  { code: "HU", label: "Hungary", phone: "36", flag: "🇭🇺" },
  { code: "ID", label: "Indonesia", phone: "62", flag: "🇮🇩" },
  { code: "IE", label: "Ireland", phone: "353", flag: "🇮🇪" },
  { code: "IL", label: "Israel", phone: "972", flag: "🇮🇱" },
  { code: "IM", label: "Isle of Man", phone: "44", flag: "🇮🇲" },
  { code: "IN", label: "India", phone: "91", flag: "🇮🇳" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
    flag: "🇮🇴",
  },
  { code: "IQ", label: "Iraq", phone: "964", flag: "🇮🇶" },
  { code: "IR", label: "Iran, Islamic Republic of", phone: "98", flag: "🇮🇷" },
  { code: "IS", label: "Iceland", phone: "354", flag: "🇮🇸" },
  { code: "IT", label: "Italy", phone: "39", flag: "🇮🇹" },
  { code: "JE", label: "Jersey", phone: "44", flag: "🇯🇪" },
  { code: "JM", label: "Jamaica", phone: "1-876", flag: "🇯🇲" },
  { code: "JO", label: "Jordan", phone: "962", flag: "🇯🇴" },
  { code: "JP", label: "Japan", phone: "81", flag: "🇯🇵", suggested: true },
  { code: "KE", label: "Kenya", phone: "254", flag: "🇰🇪" },
  { code: "KG", label: "Kyrgyzstan", phone: "996", flag: "🇰🇬" },
  { code: "KH", label: "Cambodia", phone: "855", flag: "🇰🇭" },
  { code: "KI", label: "Kiribati", phone: "686", flag: "🇰🇮" },
  { code: "KM", label: "Comoros", phone: "269", flag: "🇰🇲" },
  { code: "KN", label: "Saint Kitts and Nevis", phone: "1-869", flag: "🇰🇳" },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
    flag: "🇰🇵",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82", flag: "🇰🇷" },
  { code: "KW", label: "Kuwait", phone: "965", flag: "🇰🇼" },
  { code: "KY", label: "Cayman Islands", phone: "1-345", flag: "🇰🇾" },
  { code: "KZ", label: "Kazakhstan", phone: "7", flag: "🇰🇿" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
    flag: "🇱🇦",
  },
  { code: "LB", label: "Lebanon", phone: "961", flag: "🇱🇧" },
  { code: "LC", label: "Saint Lucia", phone: "1-758", flag: "🇱🇨" },
  { code: "LI", label: "Liechtenstein", phone: "423", flag: "🇱🇮" },
  { code: "LK", label: "Sri Lanka", phone: "94", flag: "🇱🇰" },
  { code: "LR", label: "Liberia", phone: "231", flag: "🇱🇷" },
  { code: "LS", label: "Lesotho", phone: "266", flag: "🇱🇸" },
  { code: "LT", label: "Lithuania", phone: "370", flag: "🇱🇹" },
  { code: "LU", label: "Luxembourg", phone: "352", flag: "🇱🇺" },
  { code: "LV", label: "Latvia", phone: "371", flag: "🇱🇻" },
  { code: "LY", label: "Libya", phone: "218", flag: "🇱🇾" },
  { code: "MA", label: "Morocco", phone: "212", flag: "🇲🇦" },
  { code: "MC", label: "Monaco", phone: "377", flag: "🇲🇨" },
  { code: "MD", label: "Moldova, Republic of", phone: "373", flag: "🇲🇩" },
  { code: "ME", label: "Montenegro", phone: "382", flag: "🇲🇪" },
  { code: "MF", label: "Saint Martin (French part)", phone: "590", flag: "🇲🇫" },
  { code: "MG", label: "Madagascar", phone: "261", flag: "🇲🇬" },
  { code: "MH", label: "Marshall Islands", phone: "692", flag: "🇲🇭" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
    flag: "🇲🇰",
  },
  { code: "ML", label: "Mali", phone: "223", flag: "🇲🇱" },
  { code: "MM", label: "Myanmar", phone: "95", flag: "🇲🇲" },
  { code: "MN", label: "Mongolia", phone: "976", flag: "🇲🇳" },
  { code: "MO", label: "Macao", phone: "853", flag: "🇲🇴" },
  { code: "MP", label: "Northern Mariana Islands", phone: "1-670", flag: "🇲🇵" },
  { code: "MQ", label: "Martinique", phone: "596", flag: "🇲🇶" },
  { code: "MR", label: "Mauritania", phone: "222", flag: "🇲🇷" },
  { code: "MS", label: "Montserrat", phone: "1-664", flag: "🇲🇸" },
  { code: "MT", label: "Malta", phone: "356", flag: "🇲🇹" },
  { code: "MU", label: "Mauritius", phone: "230", flag: "🇲🇺" },
  { code: "MV", label: "Maldives", phone: "960", flag: "🇲🇻" },
  { code: "MW", label: "Malawi", phone: "265", flag: "🇲🇼" },
  { code: "MX", label: "Mexico", phone: "52", flag: "🇲🇽" },
  { code: "MY", label: "Malaysia", phone: "60", flag: "🇲🇾" },
  { code: "MZ", label: "Mozambique", phone: "258", flag: "🇲🇿" },
  { code: "NA", label: "Namibia", phone: "264", flag: "🇳🇦" },
  { code: "NC", label: "New Caledonia", phone: "687", flag: "🇳🇨" },
  { code: "NE", label: "Niger", phone: "227", flag: "🇳🇪" },
  { code: "NF", label: "Norfolk Island", phone: "672", flag: "🇳🇫" },
  { code: "NG", label: "Nigeria", phone: "234", flag: "🇳🇬" },
  { code: "NI", label: "Nicaragua", phone: "505", flag: "🇳🇮" },
  { code: "NL", label: "Netherlands", phone: "31", flag: "🇳🇱" },
  { code: "NO", label: "Norway", phone: "47", flag: "🇳🇴" },
  { code: "NP", label: "Nepal", phone: "977", flag: "🇳🇵" },
  { code: "NR", label: "Nauru", phone: "674", flag: "🇳🇷" },
  { code: "NU", label: "Niue", phone: "683", flag: "🇳🇺" },
  { code: "NZ", label: "New Zealand", phone: "64", flag: "🇳🇿" },
  { code: "OM", label: "Oman", phone: "968", flag: "🇴🇲" },
  { code: "PA", label: "Panama", phone: "507", flag: "🇵🇦" },
  { code: "PE", label: "Peru", phone: "51", flag: "🇵🇪" },
  { code: "PF", label: "French Polynesia", phone: "689", flag: "🇵🇫" },
  { code: "PG", label: "Papua New Guinea", phone: "675", flag: "🇵🇬" },
  { code: "PH", label: "Philippines", phone: "63", flag: "🇵🇭" },
  { code: "PK", label: "Pakistan", phone: "92", flag: "🇵🇰" },
  { code: "PL", label: "Poland", phone: "48", flag: "🇵🇱" },
  { code: "PM", label: "Saint Pierre and Miquelon", phone: "508", flag: "🇵🇲" },
  { code: "PN", label: "Pitcairn", phone: "870", flag: "🇵🇳" },
  { code: "PR", label: "Puerto Rico", phone: "1", flag: "🇵🇷" },
  { code: "PS", label: "Palestine, State of", phone: "970", flag: "🇵🇸" },
  { code: "PT", label: "Portugal", phone: "351", flag: "🇵🇹" },
  { code: "PW", label: "Palau", phone: "680", flag: "🇵🇼" },
  { code: "PY", label: "Paraguay", phone: "595", flag: "🇵🇾" },
  { code: "QA", label: "Qatar", phone: "974", flag: "🇶🇦" },
  { code: "RE", label: "Reunion", phone: "262", flag: "🇷🇪" },
  { code: "RO", label: "Romania", phone: "40", flag: "🇷🇴" },
  { code: "RS", label: "Serbia", phone: "381", flag: "🇷🇸" },
  { code: "RU", label: "Russian Federation", phone: "7", flag: "🇷🇺" },
  { code: "RW", label: "Rwanda", phone: "250", flag: "🇷🇼" },
  { code: "SA", label: "Saudi Arabia", phone: "966", flag: "🇸🇦" },
  { code: "SB", label: "Solomon Islands", phone: "677", flag: "🇸🇧" },
  { code: "SC", label: "Seychelles", phone: "248", flag: "🇸🇨" },
  { code: "SD", label: "Sudan", phone: "249", flag: "🇸🇩" },
  { code: "SE", label: "Sweden", phone: "46", flag: "🇸🇪" },
  { code: "SG", label: "Singapore", phone: "65", flag: "🇸🇬" },
  { code: "SH", label: "Saint Helena", phone: "290", flag: "🇸🇭" },
  { code: "SI", label: "Slovenia", phone: "386", flag: "🇸🇮" },
  { code: "SJ", label: "Svalbard and Jan Mayen", phone: "47", flag: "🇸🇯" },
  { code: "SK", label: "Slovakia", phone: "421", flag: "🇸🇰" },
  { code: "SL", label: "Sierra Leone", phone: "232", flag: "🇸🇱" },
  { code: "SM", label: "San Marino", phone: "378", flag: "🇸🇲" },
  { code: "SN", label: "Senegal", phone: "221", flag: "🇸🇳" },
  { code: "SO", label: "Somalia", phone: "252", flag: "🇸🇴" },
  { code: "SR", label: "Suriname", phone: "597", flag: "🇸🇷" },
  { code: "SS", label: "South Sudan", phone: "211", flag: "🇸🇸" },
  { code: "ST", label: "Sao Tome and Principe", phone: "239", flag: "🇸🇹" },
  { code: "SV", label: "El Salvador", phone: "503", flag: "🇸🇻" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
    flag: "🇸🇽",
  },
  { code: "SY", label: "Syrian Arab Republic", phone: "963", flag: "🇸🇾" },
  { code: "SZ", label: "Swaziland", phone: "268", flag: "🇸🇿" },
  { code: "TC", label: "Turks and Caicos Islands", phone: "1-649", flag: "🇹🇨" },
  { code: "TD", label: "Chad", phone: "235", flag: "🇹🇩" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
    flag: "🇹🇫",
  },
  { code: "TG", label: "Togo", phone: "228", flag: "🇹🇬" },
  { code: "TH", label: "Thailand", phone: "66", flag: "🇹🇭" },
  { code: "TJ", label: "Tajikistan", phone: "992", flag: "🇹🇯" },
  { code: "TK", label: "Tokelau", phone: "690", flag: "🇹🇰" },
  { code: "TL", label: "Timor-Leste", phone: "670", flag: "🇹🇱" },
  { code: "TM", label: "Turkmenistan", phone: "993", flag: "🇹🇲" },
  { code: "TN", label: "Tunisia", phone: "216", flag: "🇹🇳" },
  { code: "TO", label: "Tonga", phone: "676", flag: "🇹🇴" },
  { code: "TR", label: "Turkey", phone: "90", flag: "🇹🇷" },
  { code: "TT", label: "Trinidad and Tobago", phone: "1-868", flag: "🇹🇹" },
  { code: "TV", label: "Tuvalu", phone: "688", flag: "🇹🇻" },
  { code: "TW", label: "Taiwan, Province of China", phone: "886", flag: "🇹🇼" },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
    flag: "🇹🇿",
  },
  { code: "UA", label: "Ukraine", phone: "380", flag: "🇺🇦" },
  { code: "UG", label: "Uganda", phone: "256", flag: "🇺🇬" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    flag: "🇺🇸",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598", flag: "🇺🇾" },
  { code: "UZ", label: "Uzbekistan", phone: "998", flag: "🇺🇿" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
    flag: "🇻🇦",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
    flag: "🇻🇨",
  },
  { code: "VE", label: "Venezuela", phone: "58", flag: "🇻🇪" },
  { code: "VG", label: "British Virgin Islands", phone: "1-284", flag: "🇻🇬" },
  { code: "VI", label: "US Virgin Islands", phone: "1-340", flag: "🇻🇮" },
  { code: "VN", label: "Vietnam", phone: "84", flag: "🇻🇳" },
  { code: "VU", label: "Vanuatu", phone: "678", flag: "🇻🇺" },
  { code: "WF", label: "Wallis and Futuna", phone: "681", flag: "🇼🇫" },
  { code: "WS", label: "Samoa", phone: "685", flag: "🇼🇸" },
  { code: "XK", label: "Kosovo", phone: "383", flag: "🇽🇰" },
  { code: "YE", label: "Yemen", phone: "967", flag: "🇾🇪" },
  { code: "YT", label: "Mayotte", phone: "262", flag: "🇾🇹" },
  { code: "ZA", label: "South Africa", phone: "27", flag: "🇿🇦" },
  { code: "ZM", label: "Zambia", phone: "260", flag: "🇿🇲" },
  { code: "ZW", label: "Zimbabwe", phone: "263", flag: "🇿🇼" },
];
