import { Helmet } from "react-helmet-async";
import VenueHeader from "../VenueHeader";
import FooterLogin from "src/layouts/compact/FooterLogin";
import ActiveOrder from "./ActiveOrder";

const ActiveOrderPage = () => {
  return (
    <div>
      <Helmet>
        <title>Egora-Order</title>
      </Helmet>
      <VenueHeader />
      <ActiveOrder />
      <FooterLogin />
    </div>
  );
};
export default ActiveOrderPage;
