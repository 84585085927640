import { createSlice } from "@reduxjs/toolkit";
import api from "src/utils/axios";
import axios from "axios";

const initialState = {
  isLoading: false,
  error: null,
  cardItems: [],
  isPickUp:false,
  isDineIn:false
};

const cardItemSlice = createSlice({
  name: "cardItems",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = false;
    },
    getCardItemsSuccess(state, action) {
      state.isLoading = false;
      state.cardItems = action.payload;
      state.error = null;
    },
    getCardItemsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setPickUpAndDineIn(state, action) {
      const { isPickUp, isDineIn } = action.payload;
      state.isPickUp = isPickUp;
      state.isDineIn = isDineIn;
    },
    
  },
});

export default cardItemSlice.reducer;

export const { getCardItemsSuccess, getCardItemsFailure ,setPickUpAndDineIn} =
  cardItemSlice.actions;

export function addItemsToCard(item, sessionId) {
  return async (dispatch) => {
    dispatch(cardItemSlice.actions.startLoading());
    try {
      const response = await api.post(
        `${process.env.REACT_APP_BACKEND_URL}/cart/addItemBySessionId`,
        {
          item: item,
          sessionId: sessionId,
          levelId: item?.levelId,
          venueId: item?.venueId,
        }
      );
      dispatch(cardItemSlice.actions.getCardItemsSuccess(response));
      return { data: response?.data, status: response.status };
    } catch (error) {
      dispatch(
        cardItemSlice.actions.getCardItemsFailure(error.response?.data?.messags)
      );
      throw error;
    }
  };
}

export function updateItemsToCard(item, sessionId) {
  return async (dispatch) => {
    dispatch(cardItemSlice.actions.startLoading());
    try {
      const response = await api.post(
        `${process.env.REACT_APP_BACKEND_URL}/cart/updateItemBySessionId`,
        {
          cartId: item.id,
          cartItemId: item?.cartItemId,
          sessionId: sessionId,
          item: item,
        }
      );
      dispatch(cardItemSlice.actions.getCardItemsSuccess(response));
      return { data: response?.data, status: response.status };
    } catch (error) {
      dispatch(
        cardItemSlice.actions.getCardItemsFailure(error.response?.data?.messags)
      );
    }
  };
}

export function getCartByCustomerId(sessionId) {
  return async (dispatch) => {
    dispatch(cardItemSlice.actions.startLoading());
    try {
      const response = await api.get(
        `${process.env.REACT_APP_BACKEND_URL}/cart/getCartBySessionId?id=${sessionId}`
      );
      dispatch(cardItemSlice.actions.getCardItemsSuccess(response));
    } catch (error) {
      dispatch(cardItemSlice.actions.getCardItemsSuccess([]));
      dispatch(
        cardItemSlice.actions.getCardItemsFailure(error.response?.data?.messags)
      );
    }
  };
}

export function deleteCartBySessionId(sessionId) {
  return async (dispatch) => {
    dispatch(cardItemSlice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/cart/deleteCartBySessionId?sessionId=${sessionId}`
      );
      dispatch(cardItemSlice.actions.getCardItemsSuccess(response));
      return { data: response?.data, status: response.status };
    } catch (error) {
      dispatch(
        cardItemSlice.actions.getCardItemsFailure(error.response?.data?.messags)
      );
      throw error;
    }
  };
}

export function removeItemFromCart(
  cartId,
  cartItemId,
  sessionId,
  venueId,
  levelId
) {
  return async (dispatch) => {
    dispatch(cardItemSlice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/cart/removeItemBySessionId`,
        {
          cartId: cartId,
          cartItemId: cartItemId,
          sessionId: sessionId,
          venueId: venueId,
          levelId: levelId,
        }
      );
      dispatch(cardItemSlice.actions.getCardItemsSuccess(response));
      return { data: response?.data, status: response.status };
    } catch (error) {
      dispatch(
        cardItemSlice.actions.getCardItemsFailure(error.response?.data?.messags)
      );
      throw error;
    }
  };
}
