import { Box, Typography } from "@mui/material";
const CartModalHeader = () => {
  return (
    <Box>
      <Typography
        sx={{
          color: "#F08203",
          fontWeight: "700",
          fontSize: "25px",
        }}
      >
        Cart
      </Typography>
    </Box>
  );
};

export default CartModalHeader;
