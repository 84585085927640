import React, { useState } from "react";
import { IconButton, Box } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";

const VenueProfileRating = () => {
  const [rating, setRating] = useState(0);

  const handleRatingChange = (newRating) => {
    // Update the rating state with the new value
    setRating(newRating);
    // You can also perform additional actions here, like sending the rating to a server if needed.
  };
  return (
    <Box display="flex" alignItems="center" gap={0} marginLeft={0}>
      <IconButton onClick={() => handleRatingChange(1)} sx={{ padding: 0 }}>
        {rating >= 1 ? (
          <Iconify
            sx={{
              color: " #F08203",
              height: 13,
              width: 13,
            }}
            icon="ph:star-fill"
          />
        ) : (
          <Iconify
            sx={{
              color: "#gray",
              height: 13,
              width: 13,
            }}
            icon="teenyicons:star-outline"
          />
        )}
      </IconButton>
      <IconButton onClick={() => handleRatingChange(2)} sx={{ padding: '0px 8px' }}>
        {rating >= 2 ? (
          <Iconify
            sx={{
              color: " #F08203",
              height: 13,
              width: 13,
            }}
            icon="ph:star-fill"
          />
        ) : (
          <Iconify
            sx={{
              color: "#gray",
              height: 13,
              width: 13,
            }}
            icon="teenyicons:star-outline"
          />
        )}
      </IconButton>
      <IconButton onClick={() => handleRatingChange(3)} sx={{ padding: '0px 8px 0px 0px' }}>
        {rating >= 3 ? (
          <Iconify
            sx={{
              color: " #F08203",
              height: 13,
              width: 13,
            }}
            icon="ph:star-fill"
          />
        ) : (
          <Iconify
            sx={{
              color: "#gray",
              height: 13,
              width: 13,
            }}
            icon="teenyicons:star-outline"
          />
        )}
      </IconButton>
      <IconButton onClick={() => handleRatingChange(4)} sx={{ padding: '0 8px 0px 0px' }}>
        {rating >= 4 ? (
          <Iconify
            sx={{
              color: " #F08203",
              height: 13,
              width: 13,
            }}
            icon="ph:star-fill"
          />
        ) : (
          <Iconify
            sx={{
              color: "#gray",
              height: 13,
              width: 13,
            }}
            icon="teenyicons:star-outline"
          />
        )}
      </IconButton>
      <IconButton onClick={() => handleRatingChange(5)} sx={{ padding: '0' }}>
        {rating >= 5 ? (
          <Iconify
            sx={{
              color: " #F08203",
              height: 13,
              width: 13,
            }}
            icon="ph:star-fill"
          />
        ) : (
          <Iconify
            sx={{
              color: "#gray",
              height: 13,
              width: 13,
            }}
            icon="teenyicons:star-outline"
          />
        )}
      </IconButton>
    </Box>
  );
};

export default VenueProfileRating;
