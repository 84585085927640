import { createSlice } from '@reduxjs/toolkit';
import api from 'src/utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    choiceByGroup: [],
};

const choiceByGroupSlice = createSlice({
    name: 'choiceByGroup',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        getChoiceItemsSuccess(state, action) {
            state.isLoading = false;
            state.choiceByGroup = action.payload;
            state.error = null;
        },
        getChoiceItemsFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

export default choiceByGroupSlice.reducer;

export const { getChoiceItemsSuccess, getChoiceItemsFailure } = choiceByGroupSlice.actions;

export function getWithChoiceGroup(id) {
    return async (dispatch) => {
        dispatch(choiceByGroupSlice.actions.startLoading());
        try {
            const response = await api.get(`${process.env.REACT_APP_BACKEND_URL}/items/getItem/WithChoiceGroup/?itemId=${id}`);
            dispatch(choiceByGroupSlice.actions.getChoiceItemsSuccess(response?.data)); // Pass response directly
        } catch (error) {
            dispatch(choiceByGroupSlice.actions.getChoiceItemsFailure(error.response?.data?.message)); // Corrected payload access
        }
    };
}
