import { useEffect } from "react";
import { Box, Divider, IconButton } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import VenueModalHeader from "./VenueModalHeader";
import VenueModalContent from "./VenueModalContent";
import VenueModalAction from "./VenueModalAction";
import { useSnackbar } from "../../../../components/snackbar";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCard } from "src/redux/slices/cardSlice";
import useSession from "src/utils/useSession";
import VenueSkipModalContent from "./VenueSkipModalContent";
import VenueSkipModalAction from "./VenueSkipModalAction";
import { deleteCartBySessionId } from "src/redux/slices/cardSlice";
import { setPickUpAndDineIn } from "src/redux/slices/cardSlice";
import { clearTableData } from "src/redux/slices/tableSlice";

const VenueItmeModal = ({
  width,
  open,
  item,
  modeOfVenue,
  venueId,
  onClose,
}) => {
  const [openSkipModal, setOpenSkipModal] = useState(false);
  const { tableData } = useSelector((state) => state.tableData);

  const sessionInfo = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [notes, setNotes] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.login);
  const { isLoading, choiceByGroup } = useSelector(
    (state) => state.choiceByGroup
  );
  const { cardItems } = useSelector((state) => state.cardSlice);
  const [selectedSauces, setSelectedSauces] = useState({
    items: [],
  });

  function generateRandomHexString(length) {
    let result = "";
    const characters = "0123456789abcdef";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [quantity, setQuantity] = useState(1);

  const [selectedVariant, setSelectedVariant] = useState([]);

  useEffect(() => {
    const defaultVariant = choiceByGroup?.variants?.find((item) => item.defaultVariant === true);
    setSelectedVariant(defaultVariant);
  }, [choiceByGroup.variants]);


  const toggleVariantSelect = (variant) => {
    setSelectedVariant(variant);
    setSelectedSauces({ items: [] });
  };
  const formatPrice = (value) => {
    return Math.round(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const calculateTotalPrice = () => {
    const basePrice = parseFloat(item.price.replace("Rs. ", ""));
    const addOnPrices = [].reduce((total, addon) => {
      const addonPrice = parseFloat(addon.price.replace("Rs. ", ""));
      return total + addonPrice;
    }, 0);
    return `Rs. ${(basePrice + addOnPrices) * quantity}`;
    // const totalPrice = (basePrice + addOnPrices) * quantity;
    // return `Rs. ${formatPrice(totalPrice)}`;
  };

  const handleIncreaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const toggleSauce = (elem, sauce) => {
    let updatedItems = [...selectedSauces.items];
    const itemIndex = updatedItems.findIndex((item) => item.id === elem.id);
    if (itemIndex !== -1) {
      const isSauceSelected = updatedItems[itemIndex].items.includes(sauce);
      if (isSauceSelected) {
        updatedItems[itemIndex].items = updatedItems[itemIndex].items.filter(
          (selected) => selected !== sauce
        );
      } else {
        if (updatedItems[itemIndex].items.length < elem?.quantity) {
          updatedItems[itemIndex].items.push(sauce);
        } else {
          updatedItems[itemIndex].items.shift();
          updatedItems[itemIndex].items.push(sauce);
        }
      }
      if (updatedItems[itemIndex].items.length === 0) {
        updatedItems.splice(itemIndex, 1);
      }
    } else {
      updatedItems.push({ ...elem, items: [sauce] });
    }
    const isEmpty = updatedItems.length === 0;
    setSelectedSauces(isEmpty ? { items: [] } : { items: updatedItems });
  };

  const addItemToCart = useCallback(async () => {
    let price = 0;
    if (selectedSauces?.items.length > 0) {
      price = selectedSauces.items.reduce((sum, elem) => {
        const elemTotal = elem.items
          ? elem.items.reduce(
            (innerSum, item) => innerSum + Number(item.price),
            0
          )
          : 0;
        return sum + elemTotal;
      }, 0);

      price = Math.round(price).toFixed(0);
    }
    try {
      if (tableData && tableData.venueId && venueId !== tableData.venueId) {
        setOpenSkipModal(true);
        return;
      }

      if (cardItems?.data?.items?.length > 0) {
        if (
          item?.levelId !== cardItems?.data?.levelId &&
          item?.venueId !== cardItems?.data?.levelId
        ) {
          setOpenSkipModal(true);
          return;
        }
      }


      const items = {
        ...item,
        ...(item.hasVariant ? { selectedVariant: selectedVariant } : {}),
        isPrepared: false,
        price: item.hasVariant
          ? selectedVariant.price
          : item.price,
        priceWithChoiceGroup:
          Number(item.hasVariant ? selectedVariant.price : item.price) +
          Number(price),
      };

      const res = await dispatch(
        addItemsToCard(
          {
            ...items,
            cartItemId: generateRandomHexString(24),
            qty: quantity,
            notes: notes,
            groups: selectedSauces?.items,
          },
          sessionInfo?.sessionId
        )
      );
      dispatch(
        setPickUpAndDineIn({
          isPickUp: modeOfVenue?.isPickUp,
          isDineIn: modeOfVenue?.isDineIn,
        })
      );

      if (res?.status === 201) {
        setQuantity("");
        onClose();
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
    }
  }, [selectedSauces, quantity, notes, choiceByGroup.groups, tableData]);










  const addItemToCart1 = useCallback(async () => {
    try {
      if (tableData && tableData.venueId && venueId !== tableData.venueId) {
        dispatch(clearTableData());
      }
      if (cardItems?.data?.items?.length > 0) {
        await dispatch(deleteCartBySessionId(sessionInfo?.sessionId));
      }

      const res = await dispatch(
        addItemsToCard(
          {
            ...item,
            cartItemId: generateRandomHexString(24),
            qty: quantity,
            notes: notes,
            groups: selectedSauces?.items,
            isPrepared: false,
          },
          // user?.id,?
          sessionInfo?.sessionId
        )
      );
      dispatch(
        setPickUpAndDineIn({
          isPickUp: modeOfVenue?.isPickUp,
          isDineIn: modeOfVenue?.isDineIn,
        })
      );
      if (res?.status === 201) {
        setQuantity("");
        onClose();
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
    }
  }, [selectedSauces, quantity, notes, choiceByGroup.groups, tableData]);

  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: !openSkipModal ? "space-between" : "flex-end",
            }}
          >
            {!openSkipModal && <VenueModalHeader item={item} selectedVariant={selectedVariant} />}
            <IconButton aria-label="close" onClick={onClose}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="#fca92e"
              />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ typography: "body2" }}>
          <Box>
            <Divider />
          </Box>
          {!openSkipModal ? (
            <VenueModalContent
              item={item}
              selectedSauces={selectedSauces}
              toggleSauce={toggleSauce}
              notes={notes}
              setNotes={setNotes}
              setSelectedSauces={setSelectedSauces}
              selectedVariant={selectedVariant}
              toggleVariantSelect={toggleVariantSelect}
            />
          ) : (
            <VenueSkipModalContent venueId={venueId} onClose={onClose} />
          )}

          <Divider sx={{ marginTop: "20px" }} />
        </DialogContent>

        <DialogActions sx={{ display: "block" }}>
          {!openSkipModal ? (
            <VenueModalAction
              quantity={quantity}
              setQuantity={setQuantity}
              handleIncreaseQuantity={handleIncreaseQuantity}
              handleDecreaseQuantity={handleDecreaseQuantity}
              selectedSauces={selectedSauces}
              calculateTotalPrice={calculateTotalPrice}
              addItemToCart={addItemToCart}
            />
          ) : (
            <VenueSkipModalAction
              quantity={quantity}
              setQuantity={setQuantity}
              selectedSauces={selectedSauces}
              calculateTotalPrice={calculateTotalPrice}
              addItemToCart={addItemToCart1}
              onClose={onClose}
            />
          )}
        </DialogActions>
      </ConfirmDialog>
      {/* {openSkipModal && (
        <VenueSkipModal
          width="sm"
          open={openSkipModal}
          item={item}
          notes={notes}
          onClose={closeModal}
        />
      )} */}
    </>
  );
};
export default VenueItmeModal;
