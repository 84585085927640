import PropTypes from "prop-types";
import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";
// utils
import axios from "../utils/axios";
import localStorageAvailable from "../utils/localStorageAvailable";
//
import { isValidToken, setSession, setRefresh, setLevel } from "./utils";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGIN") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === "REGISTER") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }

  if (action.type === "CHANGE_PASSWORD") {
    // Handle the state change for changing the password if needed
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }

  if (action.type === "LOGOUT") {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  // Include the case for sending verification email
  if (action.type === "SEND_VERIFICATION_EMAIL") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }

  if (action.type === "VERIFY_OTP") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const refreshToken = storageAvailable
        ? localStorage.getItem("refreshToken")
        : "";
      const accessToken = storageAvailable
        ? localStorage.getItem("accessToken")
        : "";

      if (refreshToken && isValidToken(accessToken)) {
        setSession(accessToken);
        setRefresh(refreshToken);

        // const response = await axios.get('/api/account/my-account');
        const response = await axios.post(`${backendUrl}/authv/get-profile`, {
          refreshToken,
        });

        const { user } = response.data;

        if (user.venue.levels.length) {
          if (!localStorage.getItem("levelSelected")) {
            setLevel(user?.venue?.levels[0]);
          }
        }

        user.displayName = `${user.firstName} ${user.lastName} `;

        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "INITIAL",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const backendURL = "https://api.dev.egora.pk/v1";

  const login = useCallback(async (email, password) => {
    // const response = await axios.post('https://api-dev-minimal-v4.vercel.app/api/account/login', {
    const response = await axios.post(`${backendUrl}/authc/login`, {
      email,
      password,
    });
    const { tokens, customer } = response.data;

    customer.displayName = `${customer.firstName} ${customer.lastName}`;

    setSession(tokens?.access?.token);
    setRefresh(tokens?.refresh?.token);

    dispatch({
      type: "LOGIN",
      payload: {
        customer,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(
    async (email, password, firstName, lastName, countryCode, phone, dob) => {
      const response = await axios.post(`http://localhost:3002/v1/customers/`, {
        email,
        password,
        firstName,
        lastName,
        countryCode,
        phone,
        dob,
      });
      const { accessToken, user } = response.data;

      localStorage.setItem("accessToken", accessToken);

      dispatch({
        type: "REGISTER",
        payload: {
          user,
        },
      });
    },
    []
  );

  const changePassword = useCallback(async (password, newPassword) => {
    try {
      const response = await axios.post(
        `http://localhost:3002/v1/customers/changePassword/644824427b2d7391321dc2a1`,
        {
          password,
          newPassword,
        }
      );

      // Handle the response as needed

      dispatch({
        type: "CHANGE_PASSWORD",
      });
    } catch (error) {
      console.error(error);
      // Handle password change error (e.g., display a message to the user)
    }
  }, []);

  const sendVerificationEmail = useCallback(async () => {
    try {
      const response = await axios.post(
        `http://localhost:3002/v1/customers/sendOTP/656300fc95225c694cd38b49`,
        {
          isEmail: true,
          isPhone: false,
        }
      );

      // Handle the response as needed

      dispatch({
        type: "SEND_VERIFICATION_EMAIL",
      });
    } catch (error) {
      console.error(error);
      // Handle error (e.g., display a message to the user)
    }
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: "LOGOUT",
    });
  }, []);

  const verifyOTP = useCallback(async (id, otp, isEmail) => {
    try {
      const response = await axios.post(
        `http://localhost:3002/v1/authc/verify-otp`,
        {
          id,
          otp,
          isEmail,
        }
      );

      // Handle the response as needed

      dispatch({
        type: "VERIFY_OTP",
        payload: {
          user: response.data.user, // Update with the actual response structure
        },
      });
    } catch (error) {
      console.error(error);
      // Handle OTP verification error (e.g., display a message to the user)
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: "jwt",
      login,
      register,
      logout,
      changePassword,
      sendVerificationEmail,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      register,
      changePassword,
      sendVerificationEmail,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
