// @mui
import { styled, alpha } from "@mui/material/styles";
// utils
import { bgGradient } from "../../utils/cssStyles";
import LoginBg from "src/assets/illustrations/Loginbg.svg";

// ----------------------------------------------------------------------

export const StyledRoot = styled("main")(() => ({
  height: "100%",
  display: "flex",
  position: "relative",
  
}));
export const StyledRootForFom = styled("main")(() => ({
   margin:'150px 0px 50px 0px' ,
   padding:'0px 20px',
  
}));

export const StyledSection = styled("div")(({ theme }) => ({
  display: "none",
  position: "relative",
  [theme.breakpoints.up("md")]: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

export const StyledSectionBg = styled("div")(({ theme }) => ({
  ...bgGradient({
    color: alpha(
      theme.palette.background.default,
      theme.palette.mode === "light" ? 0.9 : 0.94
    ),
    imgUrl: "/assets/background/overlay_2.jpg",
  }),
  top: 0,
  left: 0,
  zIndex: -1,
  width: "100%",
  height: "100%",
  position: "absolute",
  transform: "scaleX(-1)",

}));

export const StyledContent = styled("div")(({ theme }) => ({
  width: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",

  justifyContent: "center",
  padding: theme.spacing(15, 2),
  [theme.breakpoints.up("md")]: {
    flexShrink: 0,
    padding: theme.spacing(15, 8, 0, 8),
    width: 480,
  },
}));

export const StyledSignup = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    flexShrink: 0,
    padding: theme.spacing(0, 7, 0, 7),
    width:500
  },
}));

export const StyledSignUpForProfile = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    flexShrink: 0,
    width:500
  },
}));

export const StyledLogin = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    flexShrink: 0,
    padding: theme.spacing(0, 7, 0, 7),
    width:500
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0, 3, 0, 3),
    width: 1000,
  },
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(0, 3, 0, 3),
    width: 1000
  },
}));

// export const StyledSearchPage = styled("div")(({ theme }) => ({
//   width: "100%",
//   height: "100%",
//   marginTop: "80px",
//   display: "flex",
 
//   justifyContent: "center",
//   backgroundImage: `url(${LoginBg})`,
//   backgroundSize: "cover",
//   backgroundRepeat: "no-repeat",
//   overflow: "hidden",
//   position: "relative",

//   padding: theme.spacing(30, 2),
//   [theme.breakpoints.up("md")]: {
   
//     zIndex: "0",
//     flexShrink: 0,
//     padding: theme.spacing(30, 8, 0, 8),
//   },
// }));
