import { useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  Container,
} from "@mui/material";
import { useCart } from "../CartContext";
import { useState, useCallback } from "react";
import CartCheckoutTotalSummary from "./CartCheckoutTotalSummary";
import Iconify from "src/components/iconify";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { fetchImage } from "src/utils/fetchImage";
import { PaymentMethods } from "src/sections/payment";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import useSession from "src/utils/useSession";
import { placeOrderFromCart } from "src/redux/slices/orderPlaceSlice";
import { getCartByCustomerId } from "src/redux/slices/cardSlice";
import { fetchTableData } from "src/redux/slices/tableSlice";
import { socket } from "src/App";
import api from "src/utils/axios";

const CartCheckoutSummary = () => {
  const { calculateMenuItemTotal,resetCart } = useCart();
  const { isLoading, cardItems } = useSelector((state) => state.cardSlice);
  const navigate = useNavigate();
  const handleMenuPage = () => {
    navigate(`/venue/${cardItems?.data?.venueId}`);
  };
  const [imageURLs, setImageURLs] = useState([]);

  const [method, setMethod] = useState("cash");
 
  const [venuePermissions, setVenuePermissions] = useState();
  const [cashAvailable, setCashAvailable] = useState(true);

  useEffect(() => {
    let response;
    const fetchVenues = async () => {
      try {
        response = await api.get(`${process.env.REACT_APP_BACKEND_URL}/venues/getVenuePermission/${cardItems?.data?.venueId}`);
        setVenuePermissions(response.data)
        let orderType = localStorage.getItem("mode");
        if (orderType == 'QrPickUp' && !response?.data.configurations?.isCashAvailableOnVenueScan) {
          setMethod('card')
          setCashAvailable(false)
        }
        if (orderType == 'QrDineIn' && !response?.data.configurations?.isCashAvailableOnTableScan) {
          setMethod('card')
          setCashAvailable(false)
        }
      } catch (error) {
        console.log('Error Occured', error)
      }
    };
    fetchVenues();
  }, []);

  useEffect(() => {
    const fetchImagesForOrders = async () => {
      if (cardItems && cardItems?.data?.items?.length > 0) {
        const urls = await Promise.all(
          cardItems?.data?.items.map(async (elem) => {
            try {
              const res = await fetchImage(elem.photoURL);
              return res;
            } catch (error) {
              console.error("Error fetching data:", error);
              return null;
            }
          })
        );
        setImageURLs(urls);
      }
    };

    fetchImagesForOrders();
  }, [cardItems]);

  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  let orderType = localStorage.getItem("mode");
  const sessionInfo = useSession();
  const dispatch = useDispatch();
  const { calculateFinalTotal } = useCart();
  const { tableData } = useSelector((state) => state.tableData);

  const { user } = useSelector((state) => state.login);

  const placeOrder = useCallback(async () => {
    if ((!user?.isPhoneVerified && user) || (orderType == 'QrPickUp' && !user?.isPhoneVerified) || (orderType == 'pickUp' && !user?.isPhoneVerified)) {
      if(user?.phone){
        let queryParams = new URLSearchParams();
        queryParams.append('verify', 'Phone');
        navigate(`/auth/verify?${queryParams.toString()}`);
      }else{
        navigate(`/phoneNumber`);
      }
    } else {
      const guestUserData = JSON.parse(localStorage.getItem("guestUser"));
      let total = calculateFinalTotal(cardItems?.data?.items);
      let price = total.replace(/Rs\.\s*/i, "");
      try {
        let response = await dispatch(
          placeOrderFromCart(
            { ...cardItems?.data },
            user?.id,
            price,
            sessionInfo?.sessionId,
            orderType,
            guestUserData,
            tableData?.id,
            method
            // selectedTip,
          )
        );
        if (response?.status === 201) {
          await dispatch(getCartByCustomerId(sessionInfo?.sessionId));
          await dispatch(fetchTableData(tableData?.id));
          socket.emit("addIncomingOrder", { order: response.data });
          resetCart()
          navigate("/active");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [cardItems, tableData]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen width="100px" height="100px" marginT="0" h="70vh" />
      ) : (
        <Box
          mb={5}
          sx={{
            marginLeft: "35px",
            marginRight: "35px",
            "@media (max-width: 1025px)": {
              marginLeft: "0px",
              marginRight: "0px",
            },
          }}
        >
          <Container maxWidth="xl">
            <Grid
              container
              spacing={3}
              sx={{
                marginTop: "120px",
                alignItems: "stretch",
              }}
            >
              <Grid item xs={12} md={4}>
                <Box
                  mb={5}
                  sx={{
                    border: "1px solid rgba(0, 0, 0, 0.10)",
                    borderRadius: "20px",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: "700",
                      marginLeft: "20px",
                      marginTop: "0px",
                      fontSize: "25px",
                      padding: "10px",
                    }}
                  >
                    Order Summary
                  </Typography>
                  <Divider />

                  {cardItems?.data?.items &&
                    cardItems?.data?.items?.length > 0 ? (
                    <>
                      {cardItems.data?.items?.map((cartItem, index) => (
                        <>
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              padding: "10px 0",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                gap: "30px",
                                "@media (max-width: 600px)": {
                                  gap: "10px",
                                  marginLeft: "0px",
                                },
                              }}
                            >
                              {cartItem.qty > 0 && (
                                <Stack
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    gap: "20px",
                                    color: "#F08203",
                                    marginLeft: "20px",
                                    "@media (max-width: 600px)": {
                                      gap: "10px",
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "20px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {cartItem.qty}
                                  </Typography>
                                  <Typography sx={{ fontSize: "18px" }}>
                                    x
                                  </Typography>
                                </Stack>
                              )}
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {/* <img
                              src={imageURLs[index]}
                              style={{
                                height: "80px",
                                width: "80px",
                              }}
                            /> */}
                                <Stack
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    "@media (max-width: 600px)": {
                                      flexDirection: "column",
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      "@media (max-width: 600px)": {
                                        marginLeft: "5px",
                                      },
                                    }}
                                  >
                                    {cartItem.name}
                                  </Typography>
                                  {cartItem?.selectedVariant && (
                                    <Typography sx={{ fontSize: 12 }}>
                                      Variant: {cartItem?.selectedVariant?.name}
                                    </Typography>
                                  )}

                                  {cartItem?.groups &&
                                    cartItem?.groups?.length > 0 && (
                                      <>
                                        {cartItem?.groups?.map((sauce) => (
                                          <Typography
                                            key={index}
                                            variant="caption"
                                            sx={{
                                              color: "#666",
                                              display: "flex",
                                              gap: "2px",
                                              fontWeight: "bold",
                                              "@media (max-width: 600px)": {
                                                fontSize: "10px",
                                              },
                                            }}
                                          >
                                            {sauce?.name} :
                                            <Typography
                                              variant="caption"
                                              sx={{
                                                color: "#666",
                                                display: "flex",
                                                gap: "2px",
                                                "@media (max-width: 600px)": {
                                                  fontSize: "10px",
                                                },
                                              }}
                                            >
                                              {sauce?.items?.map(
                                                (sauce, sauceIndex) => (
                                                  <span key={sauceIndex}>
                                                    {sauce?.item}
                                                    {` (Rs. ${sauce.price * cartItem.qty})`}
                                                    {sauceIndex !==
                                                      sauce?.length - 1 && ", "}
                                                  </span>
                                                )
                                              )}
                                            </Typography>
                                          </Typography>
                                        ))}
                                      </>
                                    )}
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "red",
                                      display: "flex",
                                      marginLeft: "10px",
                                      gap: "2px",
                                      "@media (max-width: 600px)": {
                                        fontSize: "10px",
                                      },
                                    }}
                                  >
                                    <span>{cartItem?.notes}</span>
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Box>
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#F08203",
                                  marginTop: "4px",
                                  fontSize: "16px",

                                  marginRight: "15px",
                                  "@media (max-width: 1440px)": {
                                    fontSize: "14px",
                                  },
                                }}
                              >
                                {calculateMenuItemTotal(cartItem)}
                              </Typography>
                            </Stack>
                          </Box>

                          <Divider
                            sx={{
                              marginRight: "13px",
                              marginLeft: "8px",
                              marginTop: "10px",
                              color: "black",
                            }}
                          />
                        </>
                      ))}
                    </>
                  ) : (
                    <Box>
                      <Typography
                        sx={{
                          height: "20vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "25px",
                        }}
                      >
                        Cart is Empty
                      </Typography>
                    </Box>
                  )}
                  <>
                    {cardItems?.data?.items &&
                      cardItems?.data?.items?.length > 0 && (
                        <Link
                          href="#"
                          underline="none"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                            marginRight: "20px",
                          }}
                        >
                          <Button
                            onClick={handleMenuPage}
                            size="small"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                            sx={{
                              my: 1.5,
                              fontSize: "16px",
                              fontWeight: "400",
                            }}
                          >
                            Add More items
                          </Button>
                        </Link>
                      )}
                  </>
                </Box>
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        border: "1px solid rgba(0, 0, 0, 0.10)",
                        borderRadius: "20px",
                      }}
                    >
                      <PaymentMethods
                        method={method}
                        cashAvailable={cashAvailable}
                        setMethod={setMethod}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CartCheckoutTotalSummary
                      method={method}
                      cashAvailable={cashAvailable}
                      placeOrder={placeOrder}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};
export default CartCheckoutSummary;
