import { Box, Typography, Button } from "@mui/material";
import Image from "src/components/image/Image";

const Preparing = ({ detail, toggleContent, setToggleContent }) => {
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "25px",
          fontWeight: "bold",
        }}
      >
        Preparing your order <br />
        <Typography sx={{ margin: "0px" }}>
          Estimated Time 20-25 mins
        </Typography>
        <Box mt={3} mb={1}>
          <Image
            src="/assets/order/preparingProgress.svg"
            alt="image not exis"
            width="100%"
          />
        </Box>
        <Typography sx={{ margin: "0px" }}>
          The restaurant is preparing your order
        </Typography>
        <span style={{ color: "#fda92d", fontSize: "14px" }}>
          Order #{detail?._id?.replace(/^.{18}/, "OD")}
        </span>
      </Typography>

      <Box
        mt={3}
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Image
          src="/assets/order/preparing.gif"
          sx={{ width: "50%" }}
          alt="image not exis"
        />
      </Box>

      <Box mt={2} mb={4}>
        <Button
          onClick={() => setToggleContent(!toggleContent)}
          type="button"
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            height: "40px",
            fontSize: "16px",
          }}
        >
          {toggleContent ? "Hide order details" : "show order details"}
        </Button>
      </Box>
    </Box>
  );
};
export default Preparing;
