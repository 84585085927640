import { Box, Typography } from '@mui/material';
import React from 'react'
import verified from "../../assets/icons/Verifiedtick.svg";
import { LoadingButton } from '@mui/lab';
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const VerifySuccess = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    let orderType = localStorage.getItem("mode");
    if(orderType == 'QrDineIn' || orderType == 'QrPickUp' || verificationType == 'Phone'){
     navigate('/checkout')
    }else{
      navigate(`/venue`);
    }
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const verificationType = queryParams.get('verify');
  const from = location.state?.from || '/';
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="5vh"
      >
        <img src={verified} alt="verification" />
      </Box>
      <Typography variant="h3" paragraph>
        Verified
      </Typography>

      <Typography sx={{ color: "text.secondary", mb: 5 }}>
        You have successfully verified the {verificationType}
      </Typography>
      <LoadingButton
        fullWidth
        onClick={handleGoBack}
        // component={RouterLink}
        // to={'/venue'}
        size="large"
        type="submit"
        variant="contained"
        // loading={isSubmitting}
        sx={{ mt: 3 }}
      >
        Go
      </LoadingButton>
    </>
  );
}

export default VerifySuccess
