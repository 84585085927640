import { useCallback, useState } from "react";
import {
  Box,
  Card,
  Stack,
  Divider,
  Typography,
  CardContent,
  IconButton,
  Button,
} from "@mui/material";
import Iconify from "src/components/iconify";
import { useCart } from "../CartContext";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import useSession from "src/utils/useSession";
import LoginModal from "src/sections/auth/modal/loginModal/LoginModal";
const CartCheckoutTotalSummary = ({ method, placeOrder , cashAvailable}) => {
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  const { calculateSubTotal, calculateFinalTotal, selectedTip } = useCart();
  const { cardItems } = useSelector((state) => state.cardSlice);

  return (
    <>
      <Card
        sx={{
          // mb: 3,
          // marginTop: cardItems?.data?.items?.length > 0 ? "110px" : "none",
          border: "1px solid rgba(0, 0, 0, 0.10)",
          borderBottom: "none",
          borderRadius: "20px",
        }}
      >
        <Box>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "700",
              marginLeft: "25px",
              fontSize: "25px",
              padding: "10px",
            }}
          >
            Order Total
          </Typography>
          <Divider />
        </Box>

        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Sub Total
              </Typography>
              {cardItems?.data?.items && cardItems?.data?.items?.length > 0 && (
                <Typography variant="subtitle2">
                  {calculateSubTotal(cardItems?.data?.items)}
                </Typography>
              )}
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Service Fee
                <IconButton>
                  <Iconify
                    sx={{
                      height: "14px",
                      width: "14px",
                    }}
                    icon={"ph:info"}
                  />{" "}
                </IconButton>
              </Typography>
              {cardItems?.data?.items && cardItems?.data?.items?.length > 0 && (
                <Typography variant="subtitle2">Rs. 10</Typography>
              )}
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Tip
              </Typography>
              {cardItems?.data?.items && cardItems?.data?.items.length > 0 && (
                <Typography variant="subtitle2">
                  Rs. {selectedTip ? selectedTip : "0"}
                </Typography>
              )}
            </Stack>

            {/* <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: "#FCA92E" }}>
                Credit Amount
              </Typography>
              {cardItems?.data?.items.length > 0 && (
                <Typography variant="subtitle2" sx={{ color: "#FCA92E" }}>
                  Rs. 25
                </Typography>
              )}
            </Stack> */}

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: "#FCA92E" }}>
                Total Amount
              </Typography>
              {cardItems?.data?.items && cardItems?.data?.items.length > 0 && (
                <Typography variant="subtitle2" sx={{ color: "#FCA92E" }}>
                  {calculateFinalTotal(cardItems?.data?.items)}
                </Typography>
              )}
            </Stack>
           
            {method === "cash" && cashAvailable && (
              <>
                <Divider />
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={placeOrder}
                  sx={{
                    // width: "450px",
                    height: "45px",
                    fontSize: "16px",
                  }}
                >
                  Place Order
                </Button>
              </>
            )}

            {/* <CheckoutPaymentMethods /> */}
          </Stack>
        </CardContent>
      </Card>

      {openModal && (
        <LoginModal
          width="sm"
          open={openModal}
          onClose={closeModal}
          setOpenModal={setOpenModal}
        />
      )}
    </>
  );
};

export default CartCheckoutTotalSummary;
