import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import { getWithChoiceGroup } from "src/redux/slices/choiceByGroupSlice";
import ChoiceGroupItems from "./ChoiceGroup";
import Variants from "./Variants";
import { Stack } from "@mui/system";
const VenueModalContentEdit = ({
  item,
  selectedSauces,
  toggleSauce,
  notes,
  setNotes,
  setSelectedSauces,
  selectedVariant,
  toggleVariantSelect
}) => {
  const { isLoading, choiceByGroup } = useSelector(
    (state) => state.choiceByGroup
  );



  const dispatch = useDispatch();
  const getChoiceGroup = useCallback(async () => {
    try {
      await dispatch(getWithChoiceGroup(item?.id));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [item]);

  useEffect(() => {
    getChoiceGroup();
  }, []);



  return (
    <>
      {isLoading ? (
        <LoadingScreen width="70px" height="70px" marginT="20px" h="0" />
      ) : (
        <Stack>
          {choiceByGroup?.groups?.length > 0 ? (
            <ChoiceGroupItems choiceByGroup={choiceByGroup} selectedSauces={selectedSauces} toggleSauce={toggleSauce} />
          ) :
            <>
              <Variants variants={choiceByGroup} selectedVariant={selectedVariant} selectedSauces={selectedSauces} toggleSauce={toggleSauce} toggleVariantSelect={toggleVariantSelect} />
            </>}
        </Stack>
      )}

      <Box>
        <TextField
          placeholder="Any dietary / special instruction (optional)"
          variant="outlined"
          multiline={true}
          rows={1}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          sx={{
            marginTop: "20px",
            width: "100%",
          }}
        />
      </Box>
    </>
  );
};

export default VenueModalContentEdit;
