import { createSlice } from "@reduxjs/toolkit";
import api from "src/utils/axios";

const initialState = {
  isLoading: false,
  error: null,
  venues: [],
};

const venuesSlice = createSlice({
  name: "venues",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getVenuesSuccess(state, action) {
      state.isLoading = false;
      state.venues = action.payload;
      state.error = null;
    },
    getVenuesFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default venuesSlice.reducer;

export const { getVenuesSuccess, getVenuesFailure } = venuesSlice.actions;

export function getAllVenues() {
  return async (dispatch) => {
    dispatch(venuesSlice.actions.startLoading());
    try {
      const response = await api.get(
        `${process.env.REACT_APP_BACKEND_URL}/venues/getVenueList`
      );
      dispatch(venuesSlice.actions.getVenuesSuccess(response.data.results));
    } catch (error) {
      dispatch(
        venuesSlice.actions.getVenuesFailure(error.response?.data?.messags)
      );
    }
  };
}

export function getVenueListWithLocation(data) {
  return async (dispatch) => {
    dispatch(venuesSlice.actions.startLoading());
    try {
      const response = await api.get(
        `${process.env.REACT_APP_BACKEND_URL}/venues/getVenueListWithLocation/`,
         {
          params: {
            location: data,
            page: 1,
            configurations:{
              'isDineIn':true
            }
          }
        }
      );
      dispatch(venuesSlice.actions.getVenuesSuccess(response.data.results));
    } catch (error) {
      dispatch(
        venuesSlice.actions.getVenuesFailure(error.response?.data?.messags)
      );
    }
  };
}

export function getVenueListWithLocationAndType(data, type, val) {
  return async (dispatch) => {
    dispatch(venuesSlice.actions.startLoading());
    try {
      const response = await api.get(
        `${process.env.REACT_APP_BACKEND_URL}/venues/getVenueListWithLocation/`,
        {
          params: {
            location: data,
            page: 1,
            configurations:{
              [type]: val
            }
          }
        }
      );
      dispatch(venuesSlice.actions.getVenuesSuccess(response.data.results));
    } catch (error) {
      dispatch(
        venuesSlice.actions.getVenuesFailure(error.response?.data?.messags)
      );
    }
  };
}
