import React from "react";
import { useCart } from "../CartContext";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CartModalAction = ({ onClose }) => {
  let orderType = localStorage.getItem("mode");
  const { cardItems } = useSelector((state) => state?.cardSlice);
  const { isLoggedIn } = useSelector((state) => state.login);
  const { calculateFinalTotal } = useCart();

  const navigate = useNavigate();

  const handleCheckoutClick = () => {
    onClose();
    if(orderType=='QrPickUp'){
      navigate(`/carInformation`);
    }
    else if (isLoggedIn) {
      navigate(`/checkout`);
    } else {
      navigate(`/auth/login?type=${orderType}`);
    }
  };

  return (
    <Box>
      {cardItems?.data?.items?.length > 0 && (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
              Total
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
              {calculateFinalTotal(cardItems?.data?.items)}
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: "10px",
            }}
          >
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleCheckoutClick}
              sx={{
                width: "100%",
                height: "40px",
                fontSize: "16px",
              }}
            >
              Checkout
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                }}
              >
                {/* . {calculateFinalTotal(cardItems?.data?.items)} */}
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CartModalAction;
