import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { fetchImage } from "src/utils/fetchImage";
import Image from "src/components/image/Image";
import { minWidth } from "@mui/system";

const VenueProfileMenuCard = ({ item, setOrderItem }) => {
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    const getImage = async () => {
      try {
        const res = await fetchImage(item?.photoURL);
        setImageURL(res);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    if (item?.photoURL) {
      getImage();
    }
  }, [item]);
  const formatPrice = (value) => {
    return Math.round(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <>
      <Box
        onClick={() => setOrderItem(item)}
        sx={{
          display: "flex",
          flexDirection: "row",
          borderTop: "1px solid rgba(0, 0, 0, 0.15)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
          borderLeft: "1px solid rgba(0, 0, 0, 0.15)",
          borderRight: "1px solid rgba(0, 0, 0, 0.15)",
          borderRadius: "5px",
          padding: "10px",
          cursor: "pointer",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          width: "calc(49% )",

          "@media (max-width: 1440px)": {
            width: "calc(49% )",
          },
          "@media (max-width: 1000px)": {
            width: "calc(100% )",
          },
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            maxWidth: "100px",
            maxHeight: "100px",
            objectFit: "cover",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            src={imageURL?imageURL:'/assets/placeholder.png'}
            alt={item?.name}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "2px",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "8px",
            marginLeft: "15px",
            justifyContent: "center",
            marginBottom: "8px",
          }}
        >
          <Typography
            sx={{ fontWeight: 600 }}
            onClick={() => setOrderItem(item)}
          >
            {item.name}
          </Typography>
          <Typography
            sx={{
              color: "#F08203",
              marginTop: "4px",
              fontSize: "16px",
              "@media (max-width: 1440px)": {
                fontSize: "14px",
              },
            }}
          >
           Rs. {formatPrice(parseFloat(item.price))}
          </Typography>
          <Typography
            sx={{
              textAlign: "justify",
              fontSize: "16px",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 2,
              textOverflow: "ellipsis",
              "@media (max-width: 1440px)": {
                fontSize: "12px",
                marginRight: "20px",
              },
            }}
          >
            {item?.description}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default VenueProfileMenuCard;
