import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import VenueHeader from "../Venue/VenueHeader";
import FooterLogin from "src/layouts/compact/FooterLogin";
import { Box } from "@mui/material";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTableData, clearTableData } from "src/redux/slices/tableSlice";
import useSession from "src/utils/useSession";
import { selectedVenue } from "src/utils/venueSelectionUtils";
import {
  deleteCartBySessionId,
  getCartByCustomerId,
} from "src/redux/slices/cardSlice";

const QrTableScan = () => {
  const { tableId } = useParams();
  const navigate = useNavigate();
  const sessionInfo = useSession();

  const dispatch = useDispatch();
  const { cardItems } = useSelector((state) => state?.cardSlice);
  const [message, setMessage] = useState("");

  const RemoveItemFromCart = async () => {
    try {
      if (cardItems?.data?.items?.length > 0) {
        let res = await dispatch(deleteCartBySessionId(sessionInfo?.sessionId));
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await dispatch(fetchTableData(tableId));
        await dispatch(getCartByCustomerId(sessionInfo?.sessionId));
        if (res?.status === 200) {
          if (res?.data?.isAvailable === true) {
            if (cardItems?.data?.items?.length > 0) {
              let res = await RemoveItemFromCart();
              if (res?.status === 201) {
                localStorage.setItem("mode", "QrDineIn");
                selectedVenue(dispatch,"isDineIn", true);
                navigate(`/venue/${res?.data?.venueId}`, { replace: true });
                return
              }
            } else {
              localStorage.setItem("mode", "QrDineIn");
              selectedVenue(dispatch,"isDineIn", true);
              navigate(`/venue/${res?.data?.venueId}`, { replace: true });
              return
            }
          } else {
            dispatch(clearTableData());
            setMessage("This table is not Available");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Egora-QR Code</title>
      </Helmet>
      <VenueHeader />
      <Box
        mt={20}
        sx={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: "40px",
        }}
      >
        {message}
      </Box>
      <FooterLogin />
    </div>
  );
};

export default QrTableScan;
