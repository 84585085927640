import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router";
import HeaderSearchBar from "./HeaderSearchBar";

const VenueLocation = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  return (
    <>
      <Box>
        {location.pathname !== "/" &&
          location?.pathname !== "/auth/login" &&
          location?.pathname !== "/auth/register" &&
          location?.pathname !== "/auth/reset-password" && (
            <>
              {isMediumScreen && (
                <Box
                  sx={{
                    marginBottom:
                      location.pathname === "/venue" ? "0px" : "10px",
                  }}
                >
                  <HeaderSearchBar />
                </Box>
              )}
            </>
          )}
      </Box>
    </>
  );
};

export default VenueLocation;
