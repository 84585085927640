import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import {
  Box,
  Stack,
  Paper,
  Radio,
  Button,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
// components
import Iconify from "../../components/iconify";
//
import PaymentNewCardDialog from "./PaymentNewCardDialog";
import axios from "axios";
import BAFLForm from "./BAFLForm";
// ----------------------------------------------------------------------

const PAYMENT_OPTIONS = [
  {
    value: "cash",
    title: "Cash",
    icons: ["/assets/icons/payments/ic_paypal.svg"],
  },
  {
    value: "card",
    title: "Credit / Debit Card",
    icons: [
      "/assets/icons/payments/ic_mastercard.svg",
      "/assets/icons/payments/ic_visa.svg",
    ],
  },
];
const CARD_OPTIONS = [
  {
    value: "visa1",
    label: "**** **** **** 1212 - Jimmy Holland",
  },
  {
    value: "visa2",
    label: "**** **** **** 2424 - Shawn Stokes",
  },
  {
    value: "mastercard",
    label: "**** **** **** 4545 - Cole Armstrong",
  },
];

// ----------------------------------------------------------------------

export default function PaymentMethods({ method, setMethod, placeOrder, cashAvailable }) {
  // const [method, setMethod] = useState("paypal");

  const [open, setOpen] = useState(false);
  let [data, setData] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeMethod = (event) => {
    setMethod(event.target.value);
  };

  return (
    <>
      <Stack spacing={5} p={2}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "700",
            fontSize: "25px",
          }}
        >
          Payment Method
        </Typography>

        <RadioGroup
          value={method}
          onChange={handleChangeMethod}
          sx={{ marginTop: 0 }}
        >
          <Stack spacing={3}>
            {PAYMENT_OPTIONS.filter((option) => {
              if (option.value === "cash" && !cashAvailable) {
                return false; 
              }
              return true;
            }).map((option) => (
              <PaymentOption
                key={option.title}
                option={option}
                isSelected={method === option.value}
                hasChild={option.value === "card"}
                isCreditMethod={option.value === "card" && method === "card"}
                onOpen={handleOpen}
              />
            ))}
          </Stack>
        </RadioGroup>
      </Stack>

      <PaymentNewCardDialog open={open} onClose={handleClose} method={method}/>
    </>
  );
}

// ----------------------------------------------------------------------

PaymentOption.propTypes = {
  onOpen: PropTypes.func,
  hasChild: PropTypes.bool,
  option: PropTypes.object,
  isSelected: PropTypes.bool,
  isCreditMethod: PropTypes.bool,
};

function PaymentOption({
  option,
  hasChild,
  isSelected,
  isCreditMethod,
  onOpen,
}) {
  const { value, title, icons } = option;

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        transition: (theme) => theme.transitions.create("all"),
        ...(hasChild && {
          flexWrap: "wrap",
        }),
        ...(isSelected && {
          boxShadow: (theme) => theme.customShadows.z20,
        }),
      }}
    >
      <FormControlLabel
        value={value}
        control={
          <Radio checkedIcon={<Iconify icon="eva:checkmark-circle-2-fill" />} />
        }
        label={title}
        sx={{ py: 2, pl: 2.5, flexGrow: 1, mr: 0 }}
      />

      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        sx={{ position: "absolute", right: 20, top: 24 }}
      >
        {icons.map((icon) => (
          <Box component="img" key={icon} src={icon} />
        ))}
      </Stack>

      {isCreditMethod && (
        <Stack
          alignItems="flex-start"
          sx={{
            px: 3,
            width: 1,
          }}
        >
          {/* <TextField
            select
            fullWidth
            label="Card"
            SelectProps={{
              native: true,
            }}
          >
            {CARD_OPTIONS.map((card) => (
              <option key={card.value} value={card.value}>
                {card.label}
              </option>
            ))}
          </TextField> */}

          <Button
            size="small"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={onOpen}
            sx={{ my: 3 }}
          >
            Add new card
          </Button>
        </Stack>
      )}
      {/* <BAFLForm/> */}
    </Paper>
  );
}
