 import { createSlice } from "@reduxjs/toolkit";
import api from "src/utils/axios";
import axios from "axios";

const initialState = {
  isLoading: false,
  error: null,
  tableData: [],
};

const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    fetchTableDataSuccess(state, action) {
      state.isLoading = false;
      state.tableData = action.payload;
      state.error = null;
    },
    fetchTableDataFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    clearTableData(state) {
      state.tableData = [];
    },
  },
});

export default tableSlice.reducer;

export const { fetchTableDataSuccess, fetchTableDataFailure,clearTableData } =
  tableSlice.actions;

export function fetchTableData(tableId) {
  return async (dispatch) => {
    dispatch(tableSlice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/tables/${tableId}`
      );
      dispatch(tableSlice.actions.fetchTableDataSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      dispatch(tableSlice.actions.fetchTableDataFailure(error.message));
    }
  };
}
