import React from "react";
import { Helmet } from "react-helmet-async";
import VenueHeader from "./VenueHeader";
import VenueCuisines from "./VenueCuisines";
import FooterLogin from "src/layouts/compact/FooterLogin";
import { useSelector } from "react-redux";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";

const VenueMainPage = () => {
  const { isLoading } = useSelector((state) => state.venues);
  return (
    <div>
      <Helmet>
        <title>Egora-Venues</title>
      </Helmet>
      <VenueHeader />
      {isLoading ? (
        <LoadingScreen width="100px" height="100px" marginT="0" h="100vh" />
      ) : (
        <VenueCuisines />
      )}
      <FooterLogin />
    </div>
  );
};

export default VenueMainPage;
