import { useState } from "react";
import { Box, Divider, IconButton } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { DialogTitle, DialogContent } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import ModalHeader from "./ModalHeader";
import LoginModalContent from "./LoginModalContent";

const LoginModal = ({ width, open, onClose, setOpenModal }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <ConfirmDialog width={width} open={open} setOpenModal={setOpenModal}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <ModalHeader toggle={toggle} />
            <IconButton aria-label="close" onClick={onClose}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="#fca92e"
              />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ typography: "body2" }}>
          <Box>
            <Divider />
          </Box>
          <LoginModalContent toggle={toggle} setToggle={setToggle} />
          <Divider sx={{ marginTop: "20px" }} />
        </DialogContent>
      </ConfirmDialog>
    </>
  );
};
export default LoginModal;
