import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import { Dialog, DialogContent } from "@mui/material";
// components
import Iconify from "../../components/iconify";
import MenuPopover from "../../components/menu-popover";
import XPayPayment from "src/pages/PaymentCustomPage";

// ----------------------------------------------------------------------

PaymentNewCardDialog.propTypes = {
  onClose: PropTypes.func,
};

export default function PaymentNewCardDialog({
  data,
  onClose,
  total,
  method,
  ...other
}) {
  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <Dialog maxWidth="xs" onClose={onClose} {...other}>
        <DialogContent sx={{ overflow: "unset" }}>
          <XPayPayment method={method} onClose={onClose} />
        </DialogContent>
      </Dialog>
    </>
  );
}
