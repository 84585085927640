import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useLocation } from "react-router";
import LocationModal from "../locationSearchModal/LocationModal";

const HeaderSearchBar = () => {
  const location = useLocation();
  const address = localStorage.getItem("address");

  const [openCart, setOpenCart] = useState(false);
  const handleClose = () => {
    setOpenCart(false);
  };

  const truncateAddress = (address) => {
    const truncated = address.substr(0, 25);
    const lastSpaceIndex = truncated.lastIndexOf(" ");
    if (lastSpaceIndex !== -1 && lastSpaceIndex < 25) {
      return truncated.substr(0, lastSpaceIndex) + "...";
    } else {
      return truncated + "...";
    }
  };
  return (
    <>
      {location.pathname !== "/" &&
        location?.pathname !== "/auth/login" &&
        location?.pathname !== "/auth/register" &&
        location?.pathname !== "/auth/reset-password" && (
          <Box onClick={() => setOpenCart(true)}>
            {address && (
              <Button
                type="submit"
                variant="contained"
                sx={{
                  background: "#FCA92E",
                  height: "45px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  "@media (max-width: 600px)": {
                    width: "100%",
                    height: "40px",
                  },
                }}
              >
                <Iconify
                  sx={{
                    color: "#black",
                    height: 30,
                    width: 30,
                    "@media (max-width: 1200px)": {
                      height: 20,
                      width: 20,
                    },
                    "@media (max-width: 1000px)": {
                      width: 20,
                      height: 20,
                    },
                  }}
                  icon={"mdi:location"}
                />
                <Typography
                  sx={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "1rem",
                    "@media (max-width: 600px)": {
                      fontSize:"14px"
                    },
                  }}
                >
                  {truncateAddress(address)}
                </Typography>
              </Button>
            )}
            {/* <RHFTextField
                  name="address"
                  rules={{ required: "Address is required" }}
                  placeholder="Search your favorite venue, food and drink"
                  sx={{
                    width: "100%",
                    borderColor: "#FFC871",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-root": {
                      border: "1px solid #FFC871",
                      borderRadius: "10px",
                      "&:hover fieldset": {
                        borderColor: "#FFC871",
                      },

                      "@media (max-width: 1440px)": {
                        height: "45px",
                        width: "500px",
                      },
                      "@media (max-width: 1200px)": {
                        height: "50px",
                        width: "380px",
                      },
                      "@media (max-width: 1000px)": {
                        width: "320px",
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      <Iconify
                          sx={{
                            color: "#FCA92E",
                            height: 100,
                            width: 50,
                            p: 1,
                            "@media (max-width: 1200px)": {
                              height: 80,
                              width: 40,
                            },
                            "@media (max-width: 1000px)": {
                              width: 40,
                            },
                          }}
                          icon={"material-symbols:search"}
                        />
                      </InputAdornment>
                    ),
                  }}
                /> */}
          </Box>
        )}
      <LocationModal width="md" open={openCart} onClose={handleClose} />
    </>
  );
};

export default HeaderSearchBar;
