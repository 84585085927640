import { getGeocode, getLatLng } from "use-places-autocomplete";
import { getVenueListWithLocationAndType } from "src/redux/slices/venueSlice";

export async function selectedVenue(dispatch, type, val) {
  try {
    const address = localStorage.getItem("address");
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    const location = `${lat},${lng}`;
    localStorage.setItem("address", results[0]?.formatted_address);
    localStorage.setItem("mode", type === "isDineIn" ? "dineIn" : "pickUp");
    await dispatch(getVenueListWithLocationAndType(location, type, val));
  } catch (error) {
    console.log("Error ", error);
  }
}
