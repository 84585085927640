import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}


export const convertTimeFormat = (timeString) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const [datePart, timePart] = timeString.split('T');
  const [year, month, day] = datePart.split('-');
  const [hours, minutes] = timePart.slice(0, -5).split(':');

  const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes));

  const formattedDate = `${days[utcDate.getUTCDay()]}, ${utcDate.getUTCDate()} ${months[utcDate.getUTCMonth()]}`;
    
  const formattedTime = new Date(timeString).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit"})

  return `${formattedDate}, ${formattedTime}`;
};

