import { Box, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"; 

const VenueSkipModalContentEdit = ({ venueId, onClose }) => {
  const { tableData } = useSelector((state) => state.tableData);
  const { venues } = useSelector((state) => state.venues);
  const venue = venues.find((venue) => venue.id === tableData?.venueId);

  return (
    <>
      <Box>
        <Divider />
      </Box>
      {tableData && tableData.venueId && venueId !== tableData.venueId ? (
        <Typography mt={3}>
          You have already set the table at
          <Link
        
            to={`/venue/${tableData?.venueId}`}
            style={{ textDecoration: "none", color: "#FCA92E" ,marginLeft:'5px'}}
            onClick={() => onClose()}
          >
            {venue?.name}.
          </Link>
        </Typography>
      ) : (
        <Typography mt={3}>You have some items in your cart.</Typography>
      )}
    </>
  );
};

export default VenueSkipModalContentEdit;
