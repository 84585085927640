import axios from "axios";
export const getVerifyCode = async (data, id) => {
    try {
        const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/customers/sendOTP/${id}`, data);
        return response?.data;
    } catch (error) {
        console.error("Error fetching image:", error);
    }
};


// export const verifyOTP = async (apiData) => {
//     try {
//         const response = await axios.post(
//             `${process.env.REACT_APP_BACKEND_URL}/authc/verify-otp`,
//             apiData
//         );

//         return response?.data;
//     } catch (error) {
//         console.error("Error fetching image:", error);
//     }
// };



