import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useSelector } from "react-redux";

const VenueSkipModalAction = ({
  quantity,
  selectedSauces,
  calculateTotalPrice,
  addItemToCart,
  onClose,
}) => {
  const { choiceByGroup } = useSelector((state) => state.choiceByGroup);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "@media (max-width: 600px)": {
            flexWrap: "wrap",
          },
        }}
      >
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onClose}
          sx={{
            width: "20%",
            fontSize: "16px",
            padding: "8px 0px",
            "@media (max-width: 600px)": {
              width: "100%",
              marginTop: "20px",
            },
          }}
        >
          skip
        </Button>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            choiceByGroup?.groups?.some(
              (group) =>
                group.required &&
                !selectedSauces.items.some((item) => item.id === group.id)
            ) ||
            selectedSauces.items.find((item) => item.quantity > item.quantity)
          }
          sx={{
            width: "60%",
            fontSize: "16px",
            padding: "8px 0px",
            "@media (max-width: 600px)": {
              width: "100%",
              marginTop: "20px",
            },
          }}
          onClick={addItemToCart}
        >
          Add {quantity} to Cart
          <Typography
            sx={{
              "&::before": {
                content: '"\\2022"',
                marginRight: "8px",
                fontSize: "8px",
                color: "text.primary",
              },
              fontWeight: "700",
              ml: 2,
              fontSize: "16px",
            }}
          >
            {calculateTotalPrice()}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default VenueSkipModalAction;
