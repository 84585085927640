import { Box, Divider, Stack, Typography, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import VenueProfileRating from "./VenueProfileRating";
import Iconify from "src/components/iconify/Iconify";
import { truncateText } from "src/utils/truncateText";
import Image from "src/components/image/Image";
import { useRestaurantOpenClose } from "src/utils/useRestaurantOpenClose";

const VenueCover = ({ venuesCoverData }) => {
  const { currentDay, formattedTime, convertTimeTo12HourFormat } =
    useRestaurantOpenClose();
  let [time, setTime] = useState("");
  useEffect(() => {
    if (venuesCoverData?.detail?.venueTimings) {
      setTime(venuesCoverData?.detail?.venueTimings[currentDay]);
    }
  }, [venuesCoverData]);

  if (!venuesCoverData) {
    return <div>Venue not found</div>;
  }
  return (
    <Box
      sx={{
        // height: "450px",
        background: "#fff",
      }}
    >
      <Box
        sx={{
          boxSizing: "border-box",
          marginTop: "80px",
          width: "100%",
          height: "300px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          zIndex: "5",
          position: "relative",
          "@media (max-width: 600px)": {
            height: "210px",
          },
        }}
      >
        <Stack
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Image
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            src={venuesCoverData?.image?venuesCoverData?.image:'/assets/placeholder.png'}
            alt=""
          />
        </Stack>
      </Box>
      <Container maxWidth="xl">
        {/* <Box
          sx={{
            margin: "0px 30px",
          }}
        > */}
        <Typography
          sx={{
            color: " #F08203",
            fontWeight: "700",
            marginLeft: "-1px",
            marginTop: "5px",
            fontSize: "35px",
            zIndex: "40",
            "@media (max-width: 600px)": {
              fontSize: "25px",
            },
          }}
        >
          {venuesCoverData?.detail?.name}
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <VenueProfileRating /> */}
          {/* <Typography
              sx={{
                "&::before": {
                  content: '"\\2022"',
                  marginRight: "8px",
                  fontSize: "8px",
                  marginLeft: "8px",
                  color: "text.primary",
                },
                "&::after": {
                  content: '"\\2022"',
                  marginRight: "8px",
                  fontSize: "8px",
                  marginLeft: "8px",
                  color: "text.primary",
                },
                fontSize: "11px",
              }}
            >
              Drinks - Japanese - Food
            </Typography> */}
          <Typography
            sx={{
              fontSize: "11px",
            }}
          >
            {venuesCoverData?.distance
              ? (Number(venuesCoverData?.distance) / 1000).toFixed(1)
              : 0}{" "}
            km away
          </Typography>
        </Box>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            marginTop: "3px",
            fontWeight: "400",
            color: "#372F2F",
            lineHeight: "26px",
          }}
        >
          <Iconify
            sx={{
              height: 20,
              width: 30,
              marginLeft: "-10px",
            }}
            icon="ion:location-outline"
            color="#fca92e"
          />

          {`${
            venuesCoverData?.detail?.venueAddressOne
              ? truncateText(venuesCoverData?.detail?.venueAddressOne, 35)
              : ""
          }`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            fontSize: "14px",
            marginTop: "3px",
            fontWeight: "400",
            color: "#372F2F",
            lineHeight: "26px",
          }}
        >
          <Typography
            sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
          >
            <Typography
              sx={{ fontSize: "14px", color: "green" }}
              component="span"
            >
              {formattedTime >= time?.startTime &&
              formattedTime <= time?.endTime
                ? "Open"
                : "Close"}
            </Typography>
            <span style={{ padding: "0 5px" }}>-</span>
            <Typography
              sx={{ fontSize: "14px", marginRight: "5px" }}
              component="span"
            >
              {formattedTime >= time?.startTime &&
              formattedTime <= time?.endTime
                ? "Closes"
                : "Opens"}
            </Typography>
            <Typography sx={{ fontSize: "14px" }} component="span">
              {formattedTime >= time?.startTime &&
              formattedTime <= time?.endTime &&
              time.startTime &&
              time.endTime
                ? convertTimeTo12HourFormat(time?.endTime)
                : null}
            </Typography>
          </Typography>
        </Box>
        <Divider
          sx={{
            marginTop: "30px",
            color: "#0000040",
            border: "1px solid #D3D3D3",
          }}
        />

        {/* </Box> */}
      </Container>
    </Box>
  );
};

export default VenueCover;
