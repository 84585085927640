import React from "react";
export const splitText = (description) => {
  const words = description.split(" ");
  return words.map((word, index) => (
    <React.Fragment key={index}>
      {word}
      {index < words.length - 1 && " "}{" "}
    </React.Fragment>
  ));
};
