import { useEffect, useState } from "react";

export const useRestaurantOpenClose = () => {
  const [currentDay, setCurrentDay] = useState("");
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    let today = new Date();
    setCurrentDay(today.toLocaleDateString("en-US", { weekday: "long" }).toLowerCase());

    let hours = today.getHours().toString().padStart(2, "0");
    let minutes = today.getMinutes().toString().padStart(2, "0");
    let seconds = today.getSeconds().toString().padStart(2, "0");
    let formattedTime = hours + ":" + minutes + ":" + seconds;
    setFormattedTime(formattedTime);
  }, []);

  const convertTimeTo12HourFormat = (timeString) => {
    let hour = parseInt(timeString?.split(":")[0], 10);
    let formattedHour = ((hour + 11) % 12) + 1 + " " + (hour < 12 ? "AM" : "PM");
    return formattedHour;
  };

  return { currentDay, formattedTime, convertTimeTo12HourFormat };
};
