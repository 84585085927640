import { Helmet } from "react-helmet-async";
import VenueHeader from "../VenueHeader";
import FooterLogin from "src/layouts/compact/FooterLogin";
import CartCheckoutSummary from "./CartCheckoutSummary";



const CartCheckout = () => {
	return (
    <div>
      <Helmet>
        <title>Egora-Checkout</title>
      </Helmet>
			<VenueHeader />
		<CartCheckoutSummary/>
      <FooterLogin />
    </div>
  );
}
export default CartCheckout