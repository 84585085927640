import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem, Typography, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "../../../components/snackbar";
import { logoutUser } from "src/redux/slices/loginSlice";

const UserProfileMenu = ({ isOpen, anchorEl, handleClose }) => {
  const { user } = useSelector((state) => state.login);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToOrder = () => {
    navigate(`/order`);
    handleClose();
  };
  const goToProfile = () => {
    navigate(`/profile`);
    handleClose();
  };
  const logout = useCallback(async () => {
    try {
      dispatch(logoutUser());
      localStorage.removeItem('access_token');
      navigate(`/`);
      // enqueueSnackbar("Logout successfully");
      handleClose();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [handleClose]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !isOpen.contains(event.target)) {
        handleClose();
      }
    };

    if (isOpen) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <Menu
      keepMounted
      id="user-menu"
      anchorEl={anchorEl}
      onClose={handleClose}
      open={Boolean(isOpen)}
      disableAutoFocusItem
    >
      <MenuItem
        onClick={handleClose}
        sx={{
          fontWeight: "bold",
        }}
      >
        {`${user?.firstName} ${user?.lastName}`}
      </MenuItem>
      <MenuItem>
        <Typography
          variant="body2"
          sx={{
            fontSize: "13px",
            marginTop: "-15px",
            color: "gray",
          }}
        >
          {user?.email}
        </Typography>
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={goToProfile}
        sx={{
          fontSize: "14px",
        }}
      >
        Profile
      </MenuItem>
      <MenuItem
        onClick={goToOrder}
        sx={{
          fontSize: "14px",
        }}
      >
        Order
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={handleClose}
        sx={{
          fontSize: "14px",
        }}
      >
        Help center
      </MenuItem>
      <MenuItem
        onClick={logout}
        sx={{
          fontSize: "14px",
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );
};

UserProfileMenu.propTypes = {
  isOpen: PropTypes.bool,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  user: PropTypes.object,
  goToProfile: PropTypes.func,
  goToOrder: PropTypes.func,
  logout: PropTypes.func,
};

export default UserProfileMenu;
