import { Box, Divider, IconButton, Typography } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import CartModalHeader from "./CartModalHeader";
import CartModalContent from "./CartModalContent";
import CartModalAction from "./CartModalAction";
import { useSelector } from "react-redux";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";

const CartItemModal = ({ width, open, item, onClose }) => {
  const { tableData } = useSelector((state) => state.tableData);
  const { isLoading } = useSelector((state) => state.cardSlice);
  let mode = localStorage.getItem("mode");
  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        {isLoading ? (
          <LoadingScreen width="100px" height="100px" marginT="0" h="50vh" />
        ) : (
          <>
            <DialogTitle>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <CartModalHeader item={item} />
                <>
                  {/* {tableData && ( */}
                  <Typography
                    sx={{
                      color: "#F08203",
                      fontWeight: "700",
                      fontSize: "25px",
                    }}
                  >
                    {mode === "dineIn"
                      ? "DineIn"
                      : mode === "pickUp"
                      ? "Pick up"
                      : ""}{" "}
                    {tableData ? tableData?.name : ""}
                  </Typography>
                  {/* )} */}
                  <IconButton aria-label="close" onClick={() => onClose(!open)}>
                    <Iconify
                      sx={{
                        height: 25,
                        width: 25,
                      }}
                      icon="mdi:close"
                      color="#fca92e"
                    />
                  </IconButton>
                </>
              </Box>
            </DialogTitle>
            <Box>
              <Divider />
            </Box>
            <DialogContent sx={{ typography: "body2" }}>
              <CartModalContent />
            </DialogContent>
            <Box>
              <Divider />
            </Box>
            <DialogActions sx={{ display: "block" }}>
              <CartModalAction open={open} onClose={onClose} />
            </DialogActions>
          </>
        )}
      </ConfirmDialog>
    </>
  );
};
export default CartItemModal;
