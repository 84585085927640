import React, { useState } from "react";
import { PaymentElement, useXpay } from "@xstak/xpay-element-stage";
import api from "src/utils/axios";
import { useCart } from "./Venue/CartContext";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import { Box } from "@mui/material";

export const Payment = ({ method, onClose }) => {
  const { cardItems } = useSelector((state) => state.cardSlice);
  const { calculateFinalTotal } = useCart();
  const total = calculateFinalTotal(cardItems?.data?.items);
  let price = total.replace(/Rs\.\s*/i, "");
  const { user } = useSelector((state) => state.login);
  let mode = localStorage.getItem("mode");
  const guestUserData = JSON.parse(localStorage.getItem("guestUser"));
  const { tableData } = useSelector((state) => state.tableData);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [binDetails, setBinDetails] = useState(null)
  const [filledAllCardDetails, setFilledAllCardDetails] = useState(false)

  const options = {
    override: true,
    fields: {
      creditCard: {
        placeholder: "4234 1234 1234 1234",
        label: "Enter your credit card",
      },
      exp: {
        placeholder: "Exp. Date",
      },
    },
    style: {
      ".input": {
        border: "1px solid black",
        "border-radius": "15px",
      },
      ".invalid": {},
      ".label": {
        "font-size": "18px",
      },
      ":focus": {},
      ":hover": {},
      "::placeholder": {},
      "::selection": {},
    },
  };
  const xpay = useXpay();
  // const pay = async () => {
  //     let customer = {};
  //     await api.post("http://localhost:3002/v1/payment/createIntent", {
  //         amount: price,
  //     })
  //         .then(async (res) => {
  //             console.log(res);
  //             console.log('res.success === true', res.success === 'true');
  //             if (res.data.success === true) {
  //                 const { pi_client_secret, encryptionKey } = res.data.data;
  //                 console.log('Jumping In')
  //                 console.log('pi_client_secret', pi_client_secret)
  //                 console.log('encryptionKey', encryptionKey)
  //                 const { message, error } = await xpay.confirmPayment("card", pi_client_secret, customer = { name: 'customer' }, encryptionKey);
  //                 console.log('Response is');
  //                 console.log(message, error);
  //                 console.log('/////////////////////////////////////////////');
  //                 //if payment is successful, request backend to create order.
  //                 //else if payment is unsuccessful, show error message
  //             }
  //         })
  // }

  const pay = async () => {
    try {
      setIsLoading(true);
      const customer1 =
        mode === "dineIn" && guestUserData
          ? guestUserData
          : { name: `${user?.firstName} ${user?.lastName}`, email: user.email };

      const shipping = {
        address1: "abc",
        city: "Islamabad",
        country: "Pakistan",
        phone: "03415555555",
        zip: "12345",
        shipping_method: "Standard",
      };

      const paymentIntentResponse = await api.post(
        `${process.env.REACT_APP_BACKEND_URL}/payment/createIntent`,
        {
          amount: price,
          currency: "PKR",
          payment_method_types: "card",
          customer: customer1,
          shipping: shipping,
          customerId: user?.id,
          levelId: cardItems?.data?.levelId,
          venueId: cardItems?.data?.venueId,
          total: price,
          type: "web",
          sessionId: cardItems?.data?.sessionId,
          orderType: mode,
          tableId: tableData?.id,
          paymentType: method,
        }
      );

      console.log(paymentIntentResponse);
      console.log(
        "res.success === true",
        paymentIntentResponse.data.response.success === true
      );

      if (paymentIntentResponse.data.response.success === true) {
        const { pi_client_secret, encryptionKey } =
          paymentIntentResponse.data.response.data;
        try {
          const { message, error } = await xpay.confirmPayment(
            "card",
            pi_client_secret,
            customer1,
            encryptionKey
          );
          if (error) {
            setIsLoading(false);
            throw new Error(`Payment failed: ${error}`);
          } else {
            const updateOrderResponse = await api.post(
              `${process.env.REACT_APP_BACKEND_URL}/cart/updateOrderStateOnPayment`,
              {
                orderId: paymentIntentResponse.data.order.id,
                cartId:  paymentIntentResponse.data.order.cartId,
                paymentIntent: paymentIntentResponse.data.response.data.pi_client_secret,
              }
            );

            console.log(updateOrderResponse.message, updateOrderResponse.error);

            if (updateOrderResponse.error) {
              setIsLoading(false);
              throw new Error(
                `Order update failed: ${updateOrderResponse.error}`
              );
            } else {
              setIsLoading(false);
              console.log("Order updated successfully:", updateOrderResponse);
              onClose();
              navigate("/active");
            }
          }
        } catch (error) {
          console.error("An error occurred:", error.message);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        throw new Error("Payment Intent creation failed");
      }
    } catch (error) {
      console.error("Error during payment process:", error);
      setIsLoading(false);
      // Handle any unexpected errors here
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
        <Box p={2}> {/* Applying padding */}

      <PaymentElement
        options={options}
        onBinDiscount={(event) => {
          setBinDetails(event.data)
          console.log("bin details", event);
        }}
        onReady={(event) => {
          setFilledAllCardDetails(event.ready)
          console.log("ready event", event);
        }}
      />
      {/* <Button type="primary" onClick={pay}>
        Pay Now
      </Button> */}
     
     {
      filledAllCardDetails &&
      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isLoading}
        onClick={pay}
        sx={{
          bgcolor: "#fda92d",
          color: (theme) =>
            theme.palette.mode === "black" ? "common." : "grey.800",
          "&:hover": {
            bgcolor: "#B66816",
            color: (theme) =>
              theme.palette.mode === "black" ? "common.white" : "grey.800",
          },
        }}
      >
        Pay Now
      </LoadingButton>
     }
      </Box>
    </>
  );
};
