import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import React,{useState} from "react";
import VenueCuisinesSlider from "./VenueSlider";
import { cuisines } from "src/_mock/map/cuisines";
import AllVenues from "./AllVenues";
import NewVenue from "./NewVenue";
import Iconify from "src/components/iconify/Iconify";
import { Container } from "@mui/system";
import { VenueSlider1 } from "./VenueSlider";

const VenueCuisines = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  return (
    <Box
      sx={{
        marginLeft: "35px",
        marginRight: "35px",
        "@media (max-width: 1025px)": {
          marginLeft: "0px",
          marginRight: "0px",
        },
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            marginTop: "210px",
            "@media (max-width: 600px)": {
              marginTop: "170px",
            },
          }}
        >
          <TextField
            fullWidth
            placeholder="Search your favorite venue, food and drink"
            variant="outlined"
            margin="dense"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{
              "& fieldset": {
                borderColor: "",
              },
              "&:hover fieldset": {
                borderColor: "#FCA92E !important",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#FCA92E !important",
              },
              "@media (max-width: 600px)": {
                "& .MuiInputBase-input": {
                  height: "16px",
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Iconify
                    sx={{
                      color: "#FCA92E",
                      height: 50,
                      width: 50,
                      p: 1,
                      cursor: "pointer",
                      "@media (max-width: 600px)": {
                        height: 40,
                        width: 40,
                      },
                    }}
                    icon={"material-symbols:search"}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {/* <Box>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              overflow: "hidden",
              "@media (max-width: 900px)": {
                marginTop: "30px",
              },
            }}
          >
            <Stack>
              <Typography
                variant="h3"
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "20px",
                  },
                }}
              >
                Explore by cuisines
              </Typography>
            </Stack>
          </Box>

          <Tabs
            ScrollButtonComponent={(props) => {
              const { direction } = props;
              return (
                <IconButton
                  disableRipple
                  sx={{
                    position: "absolute",
                    top: "41%",
                    transform: "translateY(-50%)",
                    right: 50,
                    zIndex: 1,
                    gap: "10px",
                  }}
                  {...props}
                >
                  {direction === "left" && (
                    <>
                      <VenueCuisinesSlider />
                      <VenueSlider1 />
                    </>
                  )}
                </IconButton>
              );
            }}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTab-root:not(:last-of-type)": {
                marginRight: 2,
              },
              "& .MuiTabs-scrollButtons ": {
                width: "100px",
                height: "100px",
              },
            }}
          >
            {cuisines.map((item) => (
              <Tab
                value={item.name}
                sx={{
                  maxWidth: "130px",
                  boxSizing: "border-box",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
                  borderLeft: "1px solid rgba(0, 0, 0, 0.15)",
                  borderRight: "1px solid rgba(0, 0, 0, 0.15)",
                  borderRadius: "7px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  fontWeight: "bold",
                }}
                label={
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      sx={{
                        width: "300px",
                        height: "100px",
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "10px",
                          position: "sticky",
                        }}
                        src={item.photo}
                        alt=""
                      />
                    </Box>
                    <Stack>
                      <Typography
                        sx={{
                          color: "#FCA92E",
                          fontWeight: "700",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Stack>
                  </Box>
                }
                key={item.name}
              />
            ))}
          </Tabs>
        </Box> */}

        <NewVenue searchQuery={searchQuery} />
        <AllVenues searchQuery={searchQuery}/>
      </Container>
    </Box>
  );
};

export default VenueCuisines;
