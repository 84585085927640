import { useEffect } from "react";
import { Box, Typography, Divider, IconButton } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import Image from "src/components/image/Image";
import { DialogTitle, DialogContent } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useState } from "react";
import { fetchImage } from "src/utils/fetchImage";
import Processing from "./Processing";
import Preparing from "./Preparing";
import Ready from "./Ready";

const ProcessingModal = ({ detail, width, open, onClose }) => {
  const [toggleContent, setToggleContent] = useState(false);
  const [imageURLs, setImageURLs] = useState([]);
  useEffect(() => {
    const fetchImagesForOrders = async () => {
      if (detail?.items?.length > 0) {
        const urls = await Promise.all(
          detail?.items?.map(async (elem) => {
            try {
              const res = await fetchImage(elem.photoURL);
              return res;
            } catch (error) {
              console.error("Error fetching data:", error);
              return null;
            }
          })
        );
        setImageURLs(urls);
      }
    };

    fetchImagesForOrders();
  }, [detail]);

  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box variant="h2">Order Processing</Box>
            </Box>
            <IconButton aria-label="close" onClick={onClose}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="#fca92e"
              />
            </IconButton>
          </Box>
          <Box mt={3}>
            <Divider />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ typography: "body2" }}>
          <>
            {detail?.state === "pending" && (
              <Processing
                detail={detail}
                toggleContent={toggleContent}
                setToggleContent={setToggleContent}
              />
            )}

            {detail?.state === "processing" && (
              <Preparing
                detail={detail}
                toggleContent={toggleContent}
                setToggleContent={setToggleContent}
              />
            )}

            {detail?.state === "finished" && (
              <Ready
                detail={detail}
                toggleContent={toggleContent}
                setToggleContent={setToggleContent}
              />
            )}

            {toggleContent && (
              <Box>
                <Box mt={3}>
                  <Divider />
                </Box>
                {detail?.items?.map((item, index) => {
                  return (
                    <>
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                        mb={1}
                        mt={1}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Image
                            src={imageURLs[index]?imageURLs[index]:'/assets/placeholder.png'}
                            alt={"image"}
                            style={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "5px",
                            }}
                          />

                          <Box>
                            <Box sx={{ fontSize: "14px" }}>
                              {item?.qty} X {item?.name}{" "}
                            </Box>

                            {item?.groups && item?.groups?.length > 0 && (
                              <>
                                {item?.groups?.map((sauce) => (
                                  <Typography
                                    key={index}
                                    variant="caption"
                                    sx={{
                                      color: "#666",
                                      display: "flex",
                                      marginLeft: "10px",
                                      gap: "2px",
                                      fontWeight: "bold",
                                      "@media (max-width: 600px)": {
                                        fontSize: "10px",
                                      },
                                    }}
                                  >
                                    {sauce?.name} :
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        color: "#666",
                                        display: "flex",
                                        marginLeft: "5px",
                                        gap: "2px",
                                        "@media (max-width: 600px)": {
                                          fontSize: "10px",
                                        },
                                      }}
                                    >
                                      {sauce?.items?.map(
                                        (item, index) => (
                                          <span key={index}>
                                            {item?.item}
                                            {index !== sauce.items.length - 1 && ", "}
                                          </span>
                                        )
                                      )}
                                    </Typography>
                                  </Typography>
                                ))}
                              </>
                            )}
                            <Box ml={2} sx={{ fontSize: "14px", color: "red" }}>
                              {item?.notes}
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{ color: "#F08203" }}>
                          Rs. {item?.price * item?.qty}
                        </Box>
                      </Box>
                      <Divider />
                    </>
                  );
                })}
              </Box>
            )}
          </>
        </DialogContent>
      </ConfirmDialog>
    </>
  );
};
export default ProcessingModal;
