import { Helmet } from "react-helmet-async";
import { Box, Typography, TextField, Button } from "@mui/material";
import verifyImg from "../../assets/icons/Verify.svg";
import CodeVerified from "../../assets/icons/codeVerified.svg";
import VerifySuccess from "./VerifySuccess";
import { useState } from "react";
// import { verifyPhoneNumber } from "src/utils/Apis"; // Ensure you have this function
import VenueHeader from "../Venue/VenueHeader";
import { getVerifyCode } from "src/utils/Apis";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";

export default function VerifyCodePage() {
  const [showVerified, setShowVerified] = useState(false);
  const [isCodeFormFilled, setIsCodeFormFilled] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const { user } = useSelector((state) => state.login);

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handlePhoneNumberSubmit = async () => {
    if (!phoneNumber) {
      setError("Phone number is required.");
      return;
    }
    
    setLoading(true);
    setError('');

    try {
      const getResgisteredUserId = localStorage.getItem("reg_Id");
      const apiData = { isPhone: "true", phone: phoneNumber };
      const response = await getVerifyCode(apiData, getResgisteredUserId || user?.id);
      if (response.success) {
        setShowVerified(true);
        let queryParams = new URLSearchParams();
        queryParams.append('verify', 'Phone');
        navigate(`/auth/verify?${queryParams.toString()}`);
      } else {
        setError(response.message || 'Verification failed. Please try again.');
      }

    } catch (error) {
      setError('Error verifying phone number. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <VenueHeader />
      {!showVerified && (
        <>
          <Helmet>
            <title>Verify Phone Number | Egora</title>
          </Helmet>
          <Box display="flex" justifyContent="center" mt={10}>
            <img
              src={isCodeFormFilled ? CodeVerified : verifyImg}
              alt="verification"
              style={{ width: "200px", height: "200px" }}
            />
          </Box>
          <Typography variant="h3" paragraph>
            Verification
          </Typography>
          <Typography sx={{ color: "text.secondary", mb: 5 }}>
            Enter your phone number to receive a verification code.
          </Typography>
          <Box 
            component="form" 
            noValidate 
            autoComplete="off" 
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              sx={{ mb: 2 }}
            />
            {error && (
              <Typography color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handlePhoneNumberSubmit}
              disabled={loading}
            >
              {loading ? 'Verifying...' : 'Verify Phone Number'}
            </Button>
          </Box>
        </>
      )}
      {showVerified && <VerifySuccess />}
    </>
  );
}
