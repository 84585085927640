import { useEffect, useState } from "react";
import uuidv4 from "./uuidv4";
const useSession = () => {
  const [sessionInfo, setSessionInfo] = useState(() => {
    const storedSessionInfo = localStorage.getItem("sessionInfo");
    if (storedSessionInfo) {
      const parsedSessionInfo = JSON.parse(storedSessionInfo);
      if (parsedSessionInfo.expirationTime > Date.now()) {
        return parsedSessionInfo;
      }
    }
    return { sessionId: "", expirationTime: null };
  });

  useEffect(() => {
    if (!sessionInfo.sessionId || sessionInfo.expirationTime <= Date.now()) {
      const newSessionId = uuidv4();
      const expirationTime = Date.now() + 86400000;
      const newSessionInfo = {
        sessionId: newSessionId,
        expirationTime: expirationTime,
      };
      localStorage.setItem("sessionInfo", JSON.stringify(newSessionInfo));
      setSessionInfo(newSessionInfo);
    }
  }, []);

  useEffect(() => {
    const checkExpiration = setInterval(() => {
      if (
        sessionInfo.expirationTime &&
        Date.now() > sessionInfo.expirationTime
      ) {
        const newSessionId = uuidv4();
        const expirationTime = Date.now() + 86400000;
        const newSessionInfo = {
          sessionId: newSessionId,
          expirationTime: expirationTime,
        };
        localStorage.setItem("sessionInfo", JSON.stringify(newSessionInfo));
        setSessionInfo(newSessionInfo);
      }
    }, 1000);

    return () => clearInterval(checkExpiration);
  }, [sessionInfo]);

  return sessionInfo;
};

export default useSession;
