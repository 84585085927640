import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useSelector } from "react-redux";

const VenueModalAction = ({
  quantity,
  handleIncreaseQuantity,
  handleDecreaseQuantity,
  selectedSauces,
  calculateTotalPrice,
  addItemToCart,
}) => {
  const { choiceByGroup } = useSelector((state) => state.choiceByGroup);

  const isRequiredGroupSelected = (groupId) => {
    const selectedGroup = selectedSauces.items.find(
      (item) => item.id === groupId
    );
    return (
      selectedGroup && selectedGroup.items.length >= selectedGroup.quantity
    );
  };

  const areAllRequiredGroupsSelected = choiceByGroup?.groups?.every(
    (group) => !group.required || isRequiredGroupSelected(group.id)
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "@media (max-width: 600px)": {
            flexWrap: "wrap",
          },
        }}
      >
        <Box
          variant="outlined"
          sx={{
            width: "25%",
            marginTop: "5px",
            borderRadius: "20px",
            border: "2px solid #F08203",
            "@media (max-width: 600px)": {
              width: "100%",
              padding: "3px 0px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button onClick={handleDecreaseQuantity}>
              <Iconify
                sx={{
                  color: "#F08203",
                  height: 25,
                  width: 25,
                }}
                icon={"ic:baseline-minus"}
              />
            </Button>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#F08203",
              }}
            >
              {quantity}
            </Typography>
            <Button onClick={handleIncreaseQuantity}>
              <Iconify
                sx={{
                  color: "#F08203",
                  height: 25,
                  width: 25,
                }}
                icon={"ic:baseline-plus"}
              />
            </Button>
          </Box>
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            choiceByGroup?.groups?.length > 0
              ? !areAllRequiredGroupsSelected
              : areAllRequiredGroupsSelected
          }
          sx={{
            width: "60%",
            fontSize: "16px",
            padding: "8px 0px",
            "@media (max-width: 600px)": {
              width: "100%",
              marginTop: "20px",
            },
          }}
          onClick={addItemToCart}
        >
          Add to Cart
          <Typography
            sx={{
              "&::before": {
                content: '"\\2022"',
                marginRight: "8px",
                fontSize: "8px",
                color: "text.primary",
              },
              fontWeight: "700",
              ml: 2,
              fontSize: "16px",
            }}
          >
            {calculateTotalPrice()}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default VenueModalAction;
