import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { fetchImage } from "src/utils/fetchImage";
import Image from "src/components/image/Image";
import { splitText } from "src/utils/splitText";

const VenueModalHeaderEdit = ({ item,selectedVariant }) => {
  const [imageURL, setImageURL] = useState("");
  const getImage = useCallback(async () => {
    try {
      const res = await fetchImage(item?.photoURL);
      setImageURL(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [item]);

  useEffect(() => {
    getImage();
  }, [getImage]);

 
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          "@media (max-width: 600px)": {
            display: "block",
          },
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            objectFit: "cover",
            justifyContent: "center",
          }}
        >
          <Image
            src={imageURL}
            alt={item?.name}
            sx={{
              height: "100px",
              width: "120px",
              borderRadius: "5px",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="subtitle1">
            {item?.name}
          </Typography>
          <Typography
            sx={{
              color: "#F08203",
              marginTop: "4px",
              fontSize: "16px",
              "@media (max-width: 1440px)": {
                fontSize: "15px",
              },
            }}
          >
            Rs.{" "}
            {selectedVariant
                    ? (selectedVariant?.price)
                    : (item?.price)}
          </Typography>
          <Typography
            sx={{
              textAlign: "justify",
              // fontSize: "16px",
              display: "flex",
              flexWrap: "wrap",
              "@media (max-width: 1440px)": {
                fontSize: "15px",
              },
              "@media (max-width: 600px)": {
                fontSize: "14px",
              },
            }}
          >
            {item?.description ?  splitText(item?.description):""}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default VenueModalHeaderEdit;
